import { LuTrash2 } from "react-icons/lu";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";
import { useNavigate } from "react-router";

export default function PrizesListItem({ prize }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <tr className="table_row">
        <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">
          {prize.winner_id}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {prize.name}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">
          {prize.email}
        </td>
        <td style={tBodyItemStyle} className="w-[15%]">
          {prize.game_title}
        </td>
        <td style={tBodyItemStyle} className="w-[15%]">
          {prize.tier_level}
        </td>
        <td style={tBodyItemStyle} className="w-[15%]">
          {prize.receipt_count}
        </td>
        <td style={tBodyItemStyle} className="w-[10%]">
          {prize.created_at.split("T")[0]}
        </td>
        {/* <td style={tBodyItemStyle} className="w-[20%]">
          <div className="flex justify-center gap-4 text-black">
            <div
              className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions"
              onClick={() =>
                dispatch(
                  modalActions.openModal({
                    details: {
                      id: prize.id,
                      name: prize.full_name,
                      category: "prize",
                    },
                  })
                )
              }
            >
              <LuTrash2
                style={{
                  fontSize: "16px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </div>
          </div>
        </td> */}
      </tr>
    </>
  );
}
