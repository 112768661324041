import { FiUsers } from "react-icons/fi";
import { IoRibbonOutline } from "react-icons/io5";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { HiOutlineDocumentMinus } from "react-icons/hi2";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../store/menuSlice";
import { FaPeopleLine } from "react-icons/fa6";
import { IoAnalytics } from "react-icons/io5";
import { CiBoxList, CiUser } from "react-icons/ci";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import { CiLogout } from "react-icons/ci";
import { authActions } from "../store/authSlice";
import { FaRegChartBar } from "react-icons/fa";
import { MenuItem } from "./menu/MenuItem";
import { TbMessageReport } from "react-icons/tb";
import { TbReportSearch } from "react-icons/tb";
import { MdRule } from "react-icons/md";
import { LiaDiceSolid } from "react-icons/lia";
import { PiTreeStructureLight } from "react-icons/pi";

const MENU_ITEMS = [
  {
    category: "users",
    title: "Users",
    icon: <FiUsers style={{ fontSize: "24px" }} />,
    linkTo: "/users",
  },
  {
    category: "submitted_receipts",
    title: "Submitted Receipts",
    icon: <HiOutlineDocumentText style={{ fontSize: "24px" }} />,
    linkTo: "/submitted_receipts",
  },
  {
    category: "approved_receipts",
    title: "Approved Receipts",
    icon: <HiOutlineDocumentCheck style={{ fontSize: "24px" }} />,
    linkTo: "/approved_receipts",
  },
  {
    category: "non_fiscal_receipts",
    title: "Non-fiscal Receipts",
    icon: <HiOutlineDocumentMinus style={{ fontSize: "24px" }} />,
    linkTo: "/non_fiscal_receipts",
  },
  {
    category: "games",
    title: "Games",
    icon: <PiTreeStructureLight style={{ fontSize: "24px" }} />,
    linkTo: "/games",
  },
  {
    category: "prizes",
    title: "Prizes",
    icon: <LiaDiceSolid style={{ fontSize: "24px" }} />,
    linkTo: "/prizes",
  },
  {
    category: "winners",
    title: "Winners",
    icon: <FaPeopleLine style={{ fontSize: "24px" }} />,
    linkTo: "/winners",
  },
  {
    category: "notifications",
    title: "Notifications",
    icon: <IoNotificationsOutline style={{ fontSize: "24px" }} />,
    linkTo: "/notifications",
  },
  {
    category: "reports",
    title: "Reports",
    icon: <TbReportSearch style={{ fontSize: "24px" }} />,
    linkTo: "/reports",
  },
  {
    category: "complaints",
    title: "Complaints",
    icon: <TbMessageReport style={{ fontSize: "24px" }} />,
    linkTo: "/complaints",
  },
  {
    category: "audit_logs",
    title: "Audit logs",
    icon: <IoAnalytics style={{ fontSize: "24px" }} />,
    linkTo: "/audit_logs",
  },
];

export default function Menu() {
  const isBigMenu = useSelector((state) => state.menu.isBigMenu);
  const activeCategory = useSelector((state) => state.menu.activeCategory);
  const token = localStorage.getItem("giz_superaccess");
  const [isLogoutScreenActive, setIsLogoutScreenActive] = useState(false);
  const navigate = useNavigate();
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);

  const user = jwtDecode(token);

  const dispatch = useDispatch();

  const handleCategorySelect = (e) => {
    e.stopPropagation();
    const category = e.currentTarget.getAttribute("category");
    dispatch(menuActions.changeActiveCategory({ category: category }));
    setIsDetailsExpanded(false);
  };

  const handleMenuChange = () => {
    if (!isBigMenu) {
      setIsLogoutScreenActive(false);
    }
    dispatch(menuActions.changeMenuSize());
  };

  const handleProfileClick = (e) => {
    e.stopPropagation();
    setIsLogoutScreenActive(!isLogoutScreenActive);
  };

  const handleLogout = () => {
    dispatch(authActions.removeToken());
    dispatch(menuActions.changeActiveCategory(""));
    localStorage.removeItem("giz_superaccess");
    localStorage.removeItem("giz_superrefresh");
    navigate("/login");
  };

  // const handleDetailsClick = (e) => {
  //     e.stopPropagation();
  //     setIsDetailsExpanded(!isDetailsExpanded)
  // }

  const handleDetailsSubCategoryClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const sub_category = e.target.getAttribute("name");
    navigate(`/${sub_category}`);
  };

  return (
    <div
      className={`${
        isBigMenu ? "w-[280px]" : "w-auto"
      } transition-all h-full flex-none border border-gray-200 rounded-xl flex flex-col justify-between cursor-pointer bg-white transition-all`}
      onClick={handleMenuChange}
    >
      <div>
        <div className={`flex p-6`}>
          {isBigMenu ? (
            <img
              src="/pics/full_logo.svg"
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                dispatch(menuActions.changeActiveCategory({ category: "" }));
                navigate("/");
              }}
            />
          ) : (
            <img
              src="/pics/atk.svg"
              alt=""
              width={40}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(menuActions.changeActiveCategory({ category: "" }));
                navigate("/");
              }}
            />
          )}

          {/* {isBigMenu && (
                        <img src="/pics/atk-long.svg" alt="" width={170} onClick={(e) => {
                            e.stopPropagation();
                            dispatch(menuActions.changeActiveCategory({ category: '' }))
                            navigate('/')
                        }} />
                    )} */}
        </div>
        <div className="p-4 flex flex-col gap-1">
          {MENU_ITEMS.map((item, index) => (
            <MenuItem
              key={index}
              category={item.category}
              title={item.title}
              icon={item.icon}
              linkTo={item.linkTo}
              handleClick={handleCategorySelect}
              activeCategory={activeCategory}
              isBigMenu={isBigMenu}
            />
          ))}
          {/* <MenuItem category="performance" title='Performance' icon={<FaRegChartBar style={{ fontSize: '24px' }} />} linkTo="/performance" handleClick={handleCategorySelect} activeCategory={activeCategory} isBigMenu={isBigMenu} /> */}
        </div>
      </div>
      <div className="w-full p-4">
        <div
          className="w-full items-center flex gap-4"
          onClick={handleProfileClick}
        >
          {/* <img src="/pics/user_profile.png" alt="img" className="w-[48px] h-[48px] rounded-full object-cover" /> */}
          <div className="rounded-full border border-gray-200 p-2">
            <CiUser fontSize={30} color="gray" />
          </div>
          {isBigMenu && (
            <div className="font-semibold transition-all text-sm hover:underline">
              <span>{user.name} ↓</span>
            </div>
          )}
        </div>
        <div
          className={`flex gap-2 border border-gray-200 items-center justify-center hover:border-red-200 hover:bg-red-50 text-red-600 rounded-lg ${
            isBigMenu && isLogoutScreenActive
              ? "transition-all max-h-48 max-w-82 p-2 mt-4"
              : "max-h-0 max-w-0 overflow-hidden"
          }`}
          onClick={handleLogout}
        >
          Logout <CiLogout />
        </div>
      </div>
    </div>
  );
}
