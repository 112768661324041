import { configureStore } from "@reduxjs/toolkit";
import menuSlice from "./menuSlice";
import modalSlice from "./modalSlice";
import { blogApi } from "./services/blogServices";
import { userApi } from "./services/userServices";
import authSlice from "./authSlice";
import { accessoryApi } from "./services/accessoryServices";
import alertSlice from "./alertSlice";
import userSlice from "./userSlice";
import { bodyTypeApi } from "./services/bodyTypeServices";
import { exteriorColorApi } from "./services/exteriorColorServices";
import { fuelApi } from "./services/fuelServices";
import selectedReceiptsSlice from "./selectedReceiptsSlice";
import { brandApi } from "./services/brandServices";
import accessoryBrandSelectSlice from "./accessoryBrandSelectSlice";
import characteristicsModalSlice from "./characteristicsModalSlice";
import { propertyTypeApi } from "./services/propertyTypeServices";
import { heatingTypeApi } from "./services/heatingTypeServices";
import { characteristicsApi } from "./services/characteristicsServices";
import { subPropertyTypeApi } from "./services/subPropertyServices";
import { furnishingTypeApi } from "./services/furnishingTypeServices";
import createItemModalSlice from "./createItemModalSlice";
import editItemModalSlice from "./editItemModalSlice";
import { locationsTypeApi } from "./services/locationsServices";
import { receiptsApi } from "./services/receiptsServices";
import receiptViewSlice from "./receiptViewSlice";
import { notificationApi } from "./services/notificationServices";
import approvedReceiptViewSlice from "./approvedReceiptViewSlice";
import { homepageApi } from "./services/homepageServices";
import { auditLogApi } from "./services/auditLogServices";
import auditLogViewSlice from "./auditLogViewSlice";
import nonFiscalReceiptViewSlice from "./nonFiscalReceiptViewSlice";
import { reportsApi } from "./services/reportsServices";
import { complaintsApi } from "./services/complaintsServices";
import complainReplySlice from "./complainReplySlice";
import complaintViewSlice from "./complaintViewSlice";
import { gameApi } from "./services/gameServices";
import { prizeApi } from "./services/prizeServices";
import notificationViewSlice from "./services/notificationViewSlice";
import { nacApi } from "./services/nacServices";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    menu: menuSlice.reducer,
    modal: modalSlice.reducer,
    create_item_modal: createItemModalSlice.reducer,
    edit_item_modal: editItemModalSlice.reducer,
    auth: authSlice.reducer,
    alert: alertSlice.reducer,
    characteristics_modal: characteristicsModalSlice.reducer,
    selectedReceipts: selectedReceiptsSlice.reducer,
    accessoryBrandSelect: accessoryBrandSelectSlice.reducer,
    receipt_view: receiptViewSlice.reducer,
    approved_receipt_view: approvedReceiptViewSlice.reducer,
    nonfiscal_receipt_view: nonFiscalReceiptViewSlice.reducer,
    audit_log_view: auditLogViewSlice.reducer,
    complain_reply: complainReplySlice.reducer,
    complaint_view: complaintViewSlice.reducer,
    notification_view: notificationViewSlice.reducer,
    [receiptsApi.reducerPath]: receiptsApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [accessoryApi.reducerPath]: accessoryApi.reducer,
    [bodyTypeApi.reducerPath]: bodyTypeApi.reducer,
    [exteriorColorApi.reducerPath]: exteriorColorApi.reducer,
    [fuelApi.reducerPath]: fuelApi.reducer,
    [brandApi.reducerPath]: brandApi.reducer,
    [propertyTypeApi.reducerPath]: propertyTypeApi.reducer,
    [subPropertyTypeApi.reducerPath]: subPropertyTypeApi.reducer,
    [heatingTypeApi.reducerPath]: heatingTypeApi.reducer,
    [characteristicsApi.reducerPath]: characteristicsApi.reducer,
    [furnishingTypeApi.reducerPath]: furnishingTypeApi.reducer,
    [locationsTypeApi.reducerPath]: locationsTypeApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [homepageApi.reducerPath]: homepageApi.reducer,
    [auditLogApi.reducerPath]: auditLogApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [complaintsApi.reducerPath]: complaintsApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
    [prizeApi.reducerPath]: prizeApi.reducer,
    [nacApi.reducerPath]: nacApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      receiptsApi.middleware,
      blogApi.middleware,
      userApi.middleware,
      accessoryApi.middleware,
      bodyTypeApi.middleware,
      exteriorColorApi.middleware,
      fuelApi.middleware,
      brandApi.middleware,
      propertyTypeApi.middleware,
      subPropertyTypeApi.middleware,
      heatingTypeApi.middleware,
      characteristicsApi.middleware,
      furnishingTypeApi.middleware,
      locationsTypeApi.middleware,
      notificationApi.middleware,
      homepageApi.middleware,
      auditLogApi.middleware,
      reportsApi.middleware,
      complaintsApi.middleware,
      gameApi.middleware,
      prizeApi.middleware,
      nacApi.middleware
    ),
});

export default store;
