import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const gameApi = createApi({
  reducerPath: "gameApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  }),
  endpoints: (build) => ({
    createGame: build.mutation({
      query(formData) {
        console.log("🚀 ~ query ~ formData:", formData);
        return {
          url: "/admin/games",
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
      },
      invalidatesTags: ["Games"],
    }),
    createGameRules: build.mutation({
      query(formData) {
        return {
          url: "/admin/game-rules",
          method: "POST",
          data: formData,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: ["Game"],
    }),
    getGames: build.query({
      query(params) {
        return {
          url: `/admin/games/`,
          method: "GET",
          params: params
        };
      },
      providesTags: ["Games"],
    }),
    getGameRules: build.query({
      query(params) {
        const queryParams = new URLSearchParams(params).toString();
        return {
          url: `/admin/prizes?${queryParams}`,
          method: "GET",
        };
      },
      providesTags: ["Game Rules"],
    }),
    getGameStatistics: build.query({
      query({ game_id }) {
        return {
          url: `/admin/games/filter/${game_id}`,
          method: "GET",
        };
      },
    })
  }),
});

export const {
  useCreateGameMutation,
  useCreateGameRulesMutation,
  useGetGameRulesQuery,
  useGetGamesQuery,
  useLazyGetGamesQuery,
  useGetGameStatisticsQuery,
  useLazyGetGameStatisticsQuery
} = gameApi;
