import UserListItem from "./UserListItem"

export default function UserList({ users, actions }) {
    return (
        <>
            {users?.map((user, index) => (
                <UserListItem key={index} user={user} actions={actions} />
            ))}
        </>
    )
}