import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modalSlice';
import { useDeleteAccessoryMutation } from '../store/services/accessoryServices';
import { useDeleteUserMutation } from '../store/services/userServices';
import { alertActions } from '../store/alertSlice';
import { selectedReceiptsActions } from '../store/selectedReceiptsSlice';
import { openModalWithTimeout } from '../store/alertSlice';
import { useCreatePropertyTypeMutation, useGetPropertyTypesQuery } from '../store/services/propertyTypeServices';
import { createItemModalActions } from '../store/createItemModalSlice';
import { useCreateSubPropertyTypeMutation } from '../store/services/subPropertyServices';
import { useCreateHeatingTypeMutation } from '../store/services/heatingTypeServices';
import { useCreateFurnishingTypeMutation } from '../store/services/furnishingTypeServices';
import { useCreateCharacteristicMutation } from '../store/services/characteristicsServices';
import { complainReplyActions } from '../store/complainReplySlice';
import { useSendNotificationMutation } from '../store/services/notificationServices';
import { useReplyToComplaintMutation } from '../store/services/complaintsServices';
import { IoMdSend } from "react-icons/io";
import { IoMdClose } from "react-icons/io";

const customStyles = {
    content: {
        position: 'static',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function ReplyComplain() {

    const data = useSelector(state => state.complain_reply.data)
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.complain_reply.isOpen)
    const [errors, setErrors] = useState({})
    const [sendReply, { isLoading }] = useReplyToComplaintMutation();

    console.log({ data })

    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')

    function closeModal() {
        setTitle('')
        setBody('')
        setErrors({})
        dispatch(complainReplyActions.closeModal())
    }

    const handleCancel = () => {
        setTitle('')
        setBody('')
        setErrors({})
        dispatch(complainReplyActions.closeModal())
    }

    const validateForm = () => {
        let formErrors = {};
        if (!title) formErrors.title = "Title is required";
        if (!body) formErrors.body = "Body is required";

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const notificationData = {
            notification: {
                title: title,
                body: body.replace(/<\/?[^>]+(>|$)/g, ""),
                data: {
                    userIds: [data.user_id],
                    complaint_id: data.complaint_id
                },
            }
        };

        if (validateForm()) {
            console.log({ notificationData })
            try {
                const formData = new FormData();

                formData.append('notification', JSON.stringify(notificationData))
                const result = await sendReply(notificationData).unwrap();
                console.log('Reply sent!', result);
                dispatch(openModalWithTimeout({ text: 'Reply was sent successfully!', type: 'success' }, 3000));
                closeModal()
            } catch (error) {
                dispatch(openModalWithTimeout({ text: error.data.message, type: 'error' }, 3000));
                console.error('Failed to send notification:', error);
            }
        } else {
            console.log("Validation errors");
        }
    };


    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-4 w-[600px]">
                    <div className=''>
                        <div className='mb-8 font-bold text-lg text-center'>Reply to complaint</div>
                        <div className='flex flex-col gap-4 w-full'>
                            <div className='text-sm'><span className='font-bold'>To</span>: {data.name} ({data.email})</div>
                            <div className='flex flex-col gap-1'>
                                <input
                                    type='text'
                                    autoFocus
                                    placeholder='Title'
                                    className='border border-gray-300 p-3 w-full rounded-lg text-sm'
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                {errors.title && <div className='text-sm text-red-600 font-semibold'>{errors.title}</div>}
                            </div>
                            <div className='flex flex-col gap-1'>
                                <textarea
                                    type='text'
                                    placeholder='Body'
                                    className='border border-gray-300 p-3 h-40 w-full rounded-lg text-sm resize-none'
                                    value={body}
                                    onChange={(e) => setBody(e.target.value)}
                                />
                                {errors.body && <div className='text-sm text-red-600 font-semibold'>{errors.body}</div>}
                            </div>
                            <div className="flex justify-between mt-2">
                                <div className="flex items-center gap-2 p-1 px-6 rounded-full cursor-pointer border border-gray-300 hover:bg-gray-100"
                                    onClick={handleCancel}>
                                    <span><IoMdClose size={18} /></span>
                                    <span>Cancel</span>
                                </div>
                                <div className="p-1 px-6 bg-primary text-white rounded-full cursor-pointer flex items-center gap-2 hover:bg-primary/80"
                                    onClick={handleFormSubmit}
                                >
                                    <span><IoMdSend size={18} /></span>
                                    <span>Send</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>)
}