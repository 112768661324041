import { createSlice } from "@reduxjs/toolkit";

const initialComplaintViewState = { data: {}, isOpen: false }

const complaintViewSlice = createSlice({
    name: 'complaintViewSlice',
    initialState: initialComplaintViewState,
    reducers: {
        openModal(state, action) {
            state.data = action.payload
            state.isOpen = true
        },
        closeModal(state, action) {
            state.data = {}
            state.isOpen = false
        }
    }
})

export const complaintViewActions = complaintViewSlice.actions;
export default complaintViewSlice