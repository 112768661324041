import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const receiptsApi = createApi({
    reducerPath: 'receiptsApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin`,
    }),
    endpoints: (build) => ({
        getReceipts: build.query({
            query({ params }) {
                console.log({ params })
                params = { ...params, status: 'submitted' }
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/receipts?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Submitted Receipts']
        }),
        getSubmittedReceiptById: build.query({
            query({ id }) {
                return {
                    url: `/receipts/${id}?status=submitted`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Submitted Receipt']
        }),
        getApprovedReceipts: build.query({
            query(params) {
                params = { ...params, status: 'approved' }
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/receipts?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Approved Receipts']
        }),
        getApprovedReceiptById: build.query({
            query({ id }) {
                return {
                    url: `/receipts/${id}?status=approved`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            },
            providesTags: ['Approved Receipt']
        }),
        getNonFiscalReceipts: build.query({
            query({ params }) {
                params = { ...params, status: 'non_fiscal' }
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/receipts?${queryParams}`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            }
        }),
        getNonFiscalReceiptById: build.query({
            query({ id }) {
                return {
                    url: `/receipts/${id}?status=non_fiscal`,
                    params: {},
                    method: 'GET',
                    headers: {}
                }
            }
        }),
        deleteApprovedReceipt: build.mutation({
            query(id) {
                return {
                    url: `/approved-receipts/${id}`,
                    method: 'PATCH',
                }
            },
            invalidatesTags: ['Approved Receipts']
        }),
        verifyReceipt: build.mutation({
            query({ id, data }) {
                return {
                    url: `/receipts/verify/${id}`,
                    method: 'PATCH',
                    data: data
                }
            },
            invalidatesTags: ['Submitted Receipts', 'Approved Receipts']
        }),
        getReceiptById: build.query({
            query({ id, data }) {
                return {
                    url: `/${id}`,
                    method: 'GET',
                }
            },
            invalidatesTags: ['Submitted Receipts', 'Approved Receipts']
        })
    }),
});

export const {
    useGetReceiptsQuery,
    useLazyGetReceiptsQuery,
    useGetSubmittedReceiptByIdQuery,
    useGetApprovedReceiptsQuery,
    useGetApprovedReceiptByIdQuery,
    useGetNonFiscalReceiptsQuery,
    useGetNonFiscalReceiptByIdQuery,
    useDeleteApprovedReceiptMutation,
    useVerifyReceiptMutation,
    useGetReceiptByIdQuery
} = receiptsApi;    