import { useEffect, useState } from "react"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import { useGetAccessoriesPerBrandQuery, useGetAccessoriesQuery } from "../../store/services/accessoryServices"
import CircularProgress from '@mui/material/CircularProgress';
import AccessoriesDashboardTable from "../AccessoriesDashboardTable"
import { useGetBrandsQuery } from "../../store/services/brandServices"
import AccessoriesFilters from "../AccessoriesFilters"
import { useNavigate } from "react-router"
import Pagination from "../Pagination"
import DashboardTable from "../DashboardTable"
import { useGetPropertyTypesQuery } from "../../store/services/propertyTypeServices"
import SubCategoryList from "../subcategories/ComplaintsList"
import { useGetSubPropertyTypesQuery } from "../../store/services/subPropertyServices"
import { useGetHeatingTypesQuery } from "../../store/services/heatingTypeServices"
import { BiPlus } from "react-icons/bi"
import { modalActions } from "../../store/modalSlice"
import { useDispatch } from "react-redux"
import AddItemModal from "../AddItemModal"
import ReactModal from "../ReactModal"
import { createItemModalActions } from "../../store/createItemModalSlice"
import EditItemModal from "../EditItemModal"
import ComplaintsList from "../subcategories/ComplaintsList"
import { useGetComplaintsQuery } from "../../store/services/complaintsServices"
import ReplyComplain from "../ReplyComplain"
import ComplaintView from "../ComplaintView"

export default function ComplaintsMain() {

    const [showFilters, setShowFilters] = useState(true)
    const [filterSelected, setFilterSelected] = useState("")
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    // const { data, isLoading, isError, isSuccess } = useGetAccessoriesQuery();
    const { data, isFetching, isLoading, isError, isSuccess } = useGetComplaintsQuery(params);

    console.log({ data })

    const dispatch = useDispatch()

    const navigate = useNavigate()

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams({ ...newParams, limit: 50 });
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        // if (query.has("q")) {
        //     setSearchTextInput(query.get("q"))
        // }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/complaints?${urlParams}`)
    }, [params, navigate])


    // useEffect(() => {
    //     if (data) {
    //         setFilteredAccessories(data)
    //     }
    // }, [data])

    const handleSearch = (e) => {
        // if (!e.target.value) {
        //     setFilteredAccessories(data)
        //     return
        // }
        // setFilteredAccessories(data.filter(accessory => accessory.accessory_name.toLowerCase().startsWith(e.target.value.toLowerCase())))
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    const handleFilterSelect = (e) => {
    }

    const filters = {
        handleSearch: handleSearch,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect
    }

    const details = {
        title: 'Complaints',
        // count: data?.totalCount
    }

    const buttons = [
        {
            label: '',
            color: 'bg-white',
            border: 'border border-gray-300 invisible',
            linkTo: '/complaints'
        },
    ]

    const tableData = {
        headLabels: ['Title', 'Body', 'Type', 'User'],
        data: data,
        list: <ComplaintsList items={Array.isArray(data?.data) ? data.data : []} />,
        actions: true
    }

    return (
        <>
            <ReplyComplain />
            <ComplaintView />
            <ReactModal />
            <DashboardHeader details={details} buttons={buttons} />
            <hr />
            {/* <AccessoriesFilters filters={filters} /> */}
            <div className="w-full relative">
                {isLoading ? (
                    <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-[800px] flex items-center justify-center text-primary">
                        <CircularProgress size={40} color="var(--primary-color)" />
                    </div>
                ) : (
                    isFetching && (
                        <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-full flex items-center justify-center text-primary">
                            <CircularProgress size={40} color="var(--primary-color)" />
                        </div>
                    )
                )}
                <DashboardTable tableData={tableData} />
            </div>
            {/* {!isLoading && <Pagination currentPage={currentPage} totalPages={Math.ceil(accessoriesPerBrandData?.total / 50)} onPageChange={handlePageChange} />} */}
            {/* <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
            <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                <PiArrowLeftBold style={{ fontSize: '18px' }} />
                <div className="leading-3">Previous</div>
            </div>
            <div>Pages</div>
            <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
                <div className="leading-3">Next</div>
                <PiArrowRightBold style={{ fontSize: '18px' }} />
            </div>
        </div> */}
        </>
    )
}