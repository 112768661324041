import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { auditLogViewActions } from '../../store/auditLogViewSlice';
import { useGetAuditLogByIdQuery } from '../../store/services/auditLogServices'
import { useEffect } from 'react';
import { MdHistory } from "react-icons/md";
import _ from "lodash";
import { useState } from 'react';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)'
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function AuditLogView() {

    const dispatch = useDispatch()
    const modalState = useSelector(state => state.audit_log_view)

    const { data } = useGetAuditLogByIdQuery({ id: modalState?.data.id })

    const [changedFields, setChangedFields] = useState([])

    useEffect(() => {
        setChangedFields([])
        if (modalState?.data.previous_data) {
            const fieldsChanged = findChangedFields(modalState?.data.previous_data, modalState?.data.new_data)
            setChangedFields(Object.keys(fieldsChanged))
        }
    }, [modalState])

    function closeModal() {
        dispatch(auditLogViewActions.closeModal())
    }

    function createSentence(data) {
        let sentence = 'A '
        if (data?.table_name?.includes('receipts')) {
            sentence += 'receipt '
            if (data?.table_name === 'pending_receipts') {
                sentence += 'was created '
            }
            else if (data?.table_name === 'submitted_receipts') {
                sentence += 'was submitted '
            }
            else if (data?.table_name === 'approved_receipts') {
                sentence += 'was approved '
            }
        }
        else if (data?.table_name === 'users') {
            sentence += 'user '
            if (data?.action_type === 'CREATE') {
                sentence += 'was created '
            }
            else if (data?.action_type === 'UPDATE') {
                sentence += 'was updated '
            }
            if (data?.action_type === 'SOFT-DELETE') {
                sentence += 'was soft-deleted '
            }
        }
        sentence += `at ${new Date(data.created_at).toLocaleString('en-GB', { timeZone: 'UTC' })} with the following data: `

        return sentence
    }

    function findChangedFields(oldData, newData) {
        const changedFields = {};

        Object.keys(oldData).forEach(key => {
            if (!_.isEqual(oldData[key], newData[key])) {
                changedFields[key] = { old: oldData[key], new: newData[key] };
            }
        });

        return changedFields;
    }

    const dataCellStyle = 'w-3/4 border px-4'
    const labelCellStyle = 'w-1/4 border px-4 uppercase'

    return (
        <div>
            <Modal
                isOpen={modalState?.isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-2 px-4 w-[800px] h-[600px] overflow-y-scroll scrollbar-thumb scrollbar-thin scrollbar-track">
                    <div className='flex flex-col h-full'>
                        <div className='flex items-center my-2 gap-4 justify-center text-lg uppercase'>
                            <MdHistory size={24} />
                        </div>
                        <hr />
                        <div className='h-max my-4 font-semibold text-lg flex items-center justify-center gap-4'>
                            <div>{createSentence(modalState?.data)}</div>
                        </div>
                        <table className='w-full h-full text-[14px]' border={1}>
                            <tr className='h-8'>
                                <td className={labelCellStyle}>Created At</td>
                                <td className={dataCellStyle}>{new Date(modalState?.data.created_at).toLocaleString('en-GB', { timeZone: 'UTC' })}</td>
                            </tr>
                            <tr className='h-8'>
                                <td className={labelCellStyle}>Category</td>
                                <td className={dataCellStyle}>{modalState?.data.table_name}</td>
                            </tr>
                            {modalState?.data?.table_name?.includes("receipts") && (
                                <tr className='h-8'>
                                    <td className={labelCellStyle}>VAT Number</td>
                                    <td className={dataCellStyle}>{modalState?.data.vat_number}</td>
                                </tr>
                            )}
                            <tr className='h-8'>
                                <td className={labelCellStyle}>Action</td>
                                <td className={dataCellStyle}>{modalState?.data.action_type}</td>
                            </tr>
                            <tr className='h-8'>
                                <td className={labelCellStyle}>User</td>
                                <td className={dataCellStyle}>{modalState?.data.name}</td>
                            </tr>
                            {modalState?.data.previous_data ? (
                                <>
                                    <tr className='h-8'>
                                        {modalState?.data.previous_data && (
                                            <td className='border px-4 uppercase font-semibold text-center'>Previous Data</td>
                                        )}
                                        {modalState?.data.new_data && (
                                            <td className={`border px-4 uppercase font-semibold text-center ${!modalState?.data.previous_data && 'col-span-2'}`}>New Data</td>
                                        )}
                                    </tr>
                                    <tr className='h-full'>
                                        {modalState?.data.previous_data && (
                                            <td className={`border px-4 w-1/2`}>
                                                {Object.entries(modalState?.data?.previous_data)
                                                    .filter(([key]) => key !== 'password')
                                                    .map(([key, value]) => (
                                                        <div className='flex items-center gap-2 text-[14px]'>
                                                            {/* <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value} */}
                                                            <div className='text-gray-400 w-1/2 text-right font-light'>
                                                                {key}:
                                                            </div>
                                                            {['created_at', 'birthdate', 'updated_at'].includes(key) ? (
                                                                <div className={`font-medium ${changedFields.includes(key) && 'text-red-600'}`}>{new Date(value).toLocaleString('en-GB', { timeZone: 'UTC' })}</div>
                                                            ) : (
                                                                <div className={`font-medium ${changedFields.includes(key) && 'text-red-600'}`}>{value}</div>
                                                            )}
                                                        </div>
                                                    )
                                                    )}
                                            </td>
                                        )}
                                        {modalState?.data.new_data && (
                                            <td className={`border px-4 w-1/2 ${!modalState?.data.previous_data && 'w-full col'}`}>
                                                {Object.entries(modalState?.data?.new_data)
                                                    .filter(([key]) => key !== 'password')
                                                    .map(([key, value]) => (
                                                        <div className='flex items-center gap-2 text-[14px] w-full'>
                                                            {/* <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value} */}
                                                            <div className='text-gray-400 w-1/2 text-right font-light'>
                                                                {key}:
                                                            </div>
                                                            {['created_at', 'birthdate', 'updated_at'].includes(key) ? (
                                                                <div className={`font-medium w-1/2 ${changedFields.includes(key) && 'text-green-600'}`}>{new Date(value).toLocaleString('en-GB', { timeZone: 'UTC' })}</div>
                                                            ) : (
                                                                <div className={`font-medium w-1/2 ${changedFields.includes(key) && 'text-green-600'}`}>{value}</div>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                </>
                            ) : (
                                <tr className='h-full overflow-y-scroll'>
                                    <td className={labelCellStyle}>Data</td>
                                    {modalState?.data.new_data && (
                                        <td className={`border px-4 w-1/2 ${!modalState?.data.previous_data && 'w-full col'}`}>
                                            {Object.entries(modalState?.data?.new_data)
                                                .filter(([key]) => key !== 'password')
                                                .map(([key, value]) => (
                                                    <div className='flex items-center gap-2 text-[14px]'>
                                                        {/* <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value} */}
                                                        <div className='text-gray-600'>
                                                            {key.toUpperCase()}:
                                                        </div>
                                                        {['created_at', 'birthdate', 'updated_at'].includes(key) ? (
                                                            <div className={`font-medium ${changedFields.includes(key) && 'text-green-600'}`}>{new Date(value).toLocaleString('en-GB', { timeZone: 'UTC' })}</div>
                                                        ) : (
                                                            <div className={`font-medium ${changedFields.includes(key) && 'text-green-600'}`}>{value}</div>
                                                        )}
                                                    </div>
                                                )
                                                )}
                                        </td>
                                    )}
                                </tr>
                            )}
                        </table>
                    </div>
                </div>
            </Modal>
        </div>)
}