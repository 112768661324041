import { useEffect, useState } from "react"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import { useGetAccessoriesPerBrandQuery, useGetAccessoriesQuery } from "../../store/services/accessoryServices"
import CircularProgress from '@mui/material/CircularProgress';
import AccessoriesDashboardTable from "../AccessoriesDashboardTable"
import { useGetBrandsQuery } from "../../store/services/brandServices"
import AccessoriesFilters from "../AccessoriesFilters"
import { useNavigate } from "react-router"
import Pagination from "../Pagination"
import DashboardTable from "../DashboardTable"
import { useGetPropertyTypesQuery } from "../../store/services/propertyTypeServices"
import { useGetSubPropertyTypesQuery } from "../../store/services/subPropertyServices"
import { BiPlus } from "react-icons/bi"
import AddItemModal from "../AddItemModal"
import { modalActions } from "../../store/modalSlice"
import { useDispatch } from "react-redux"
import { createItemModalActions } from "../../store/createItemModalSlice"
import EditItemModal from "../EditItemModal"
import ReactModal from "../ReactModal"
import SubPropertyList from "./SubPropertyList"
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, Pie, PieChart, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"

export default function PerformanceMain() {

    const [showFilters, setShowFilters] = useState(true)
    const [filterSelected, setFilterSelected] = useState("")
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    // const { data, isLoading, isError, isSuccess } = useGetAccessoriesQuery();
    const data = [
        {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
        },
        {
            "name": "Page C",
            "uv": 2000,
            "pv": 9800,
            "amt": 2290
        },
        {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
        },
        {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
        },
        {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800,
            "amt": 2500
        },
        {
            "name": "Page G",
            "uv": 3490,
            "pv": 4300,
            "amt": 2100
        }
    ]

    const pieChartData = [
        {
            "name": "Group A",
            "value": 400
        },
        {
            "name": "Group B",
            "value": 300
        },
        {
            "name": "Group C",
            "value": 300
        },
        {
            "name": "Group D",
            "value": 200
        },
        {
            "name": "Group E",
            "value": 278
        },
        {
            "name": "Group F",
            "value": 189
        }
    ];

    const radarData = [
        {
            "subject": "Math",
            "A": 120,
            "B": 110,
            "fullMark": 150
        },
        {
            "subject": "Chinese",
            "A": 98,
            "B": 130,
            "fullMark": 150
        },
        {
            "subject": "English",
            "A": 86,
            "B": 130,
            "fullMark": 150
        },
        {
            "subject": "Geography",
            "A": 99,
            "B": 100,
            "fullMark": 150
        },
        {
            "subject": "Physics",
            "A": 85,
            "B": 90,
            "fullMark": 150
        },
        {
            "subject": "History",
            "A": 65,
            "B": 85,
            "fullMark": 150
        }
    ]

    const dispatch = useDispatch();

    const navigate = useNavigate()

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        setParams({ ...newParams, limit: 50 });
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        // if (query.has("q")) {
        //     setSearchTextInput(query.get("q"))
        // }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/performance?${urlParams}`)
    }, [params, navigate])


    // useEffect(() => {
    //     if (data) {
    //         setFilteredAccessories(data)
    //     }
    // }, [data])

    const handleSearch = (e) => {
        // if (!e.target.value) {
        //     setFilteredAccessories(data)
        //     return
        // }
        // setFilteredAccessories(data.filter(accessory => accessory.accessory_name.toLowerCase().startsWith(e.target.value.toLowerCase())))
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    const handleFilterSelect = (e) => {
    }

    const filters = {
        handleSearch: handleSearch,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect
    }

    const details = {
        title: 'Performance',
        // count: data?.length
    }

    const buttons = [
        {
            label: 'Nenipe te prones',
            color: 'bg-white',
            border: 'border border-gray-300 invisible',
            linkTo: '/sub_property_types'
        },
        // {
        //     label: 'Shto nentip te prones',
        //     color: 'bg-gradient-to-t from-[#E2EB70] to-[#E8EF8A]',
        //     border: 'border border-b-2 border-primary',
        //     icon: <BiPlus />,
        //     shadow: 'shadow-md',
        //     onClick: () => dispatch(createItemModalActions.openModal({ details: { category: 'nentip te prones' } })),
        // }
    ]

    const tableData = {
        headLabels: ['Name', 'Property'],
        data: data,
        list: <SubPropertyList items={Array.isArray(data) ? data : []} category="nentip te prones" />
    }

    return (
        <>
            <AddItemModal />
            <EditItemModal />
            <ReactModal />
            <div className="h-full flex flex-col">
                <DashboardHeader details={details} buttons={buttons} />
                <hr />
                <div className="grid grid-cols-2 h-full">
                    <div className="h-full flex items-center justify-center">
                        <AreaChart data={data} height={300} width={800}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                            <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                        </AreaChart>
                    </div>
                    <div className="h-full flex items-center justify-center">
                        <BarChart data={data} height={300} width={800}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="pv" fill="#8884d8" />
                            <Bar dataKey="uv" fill="#82ca9d" />
                        </BarChart>
                    </div>
                    <div className="h-full flex items-center justify-center">
                        <PieChart height={400} width={800}>
                            <Pie data={pieChartData} dataKey="value" nameKey="name" cx="50%" cy="50%" fill="#82ca9d" />
                            <Legend />
                        </PieChart>
                    </div>
                    <div className="h-full flex items-center justify-center">
                        <RadarChart outerRadius={120} width={800} height={400} data={radarData}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="subject" style={{ fontSize: '14px' }} />
                            <PolarRadiusAxis angle={30} domain={[0, 150]} />
                            <Radar name="Mike" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                            <Radar name="Lily" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                            <Legend />
                        </RadarChart>
                    </div>
                </div>
            </div >
        </>
    )
}