import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { LiaUser } from "react-icons/lia";
import { exportDataToExcel } from './reports/utils';
import Confetti from 'react-confetti';
import { FaDownload } from 'react-icons/fa';
import { IoMdArrowBack } from 'react-icons/io';
import { GoEye, GoEyeClosed } from "react-icons/go";

const customStyles = {
    content: {
        position: 'static',
        borderRadius: '8px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

Modal.setAppElement(document.getElementById('root'))

const TypingEffect = () => {
    return (
        <div className="typing">
            Hello, welcome to my website!
            <p>Hello, welcome to my website!</p>
        </div>
    );
};


export default function WinnersModal({ isOpen, handleIsOpen, winners }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [dataMaskedOff, setDataMaskedOff] = useState(false)

    useEffect(() => {
        if (Object.keys(winners).length > 0) {
            setData(maskUserData(winners))
            setDataMaskedOff(true)
        }
    }, [winners])

    const handleNavigate = () => {
        handleIsOpen();
        navigate('/winners')
    }

    const handleUserDataMask = () => {
        if (dataMaskedOff) {
            setData(winners)
            setDataMaskedOff(false)
        }
        else {
            setData(maskUserData(winners))
            setDataMaskedOff(true)
        }
    }

    function maskUserData(users) {
        return users?.map(user => {
            let maskedName = user.name;
            if (user.name) {
                const [firstName, lastName] = user.name.split(" ");
                const maskedFirst = firstName ? firstName.slice(0, 1) + "*".repeat(firstName.length - 1) : "";
                const maskedLast = lastName ? lastName.slice(0, 1) + "*".repeat(lastName.length - 1) : "";
                maskedName = `${maskedFirst} ${maskedLast}`;
            }

            let maskedPersonalNumber = user.personal_number ? user.personal_number.toString() : '';
            if (maskedPersonalNumber.length === 10) {
                maskedPersonalNumber = "*".repeat(7) + maskedPersonalNumber.slice(-3);
            }

            return {
                ...user,
                name: maskedName,
                personal_number: maskedPersonalNumber
            };
        });
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <Confetti recycle={false} />
                <div className={`transition-all bg-white p-4 w-[800px]`}>
                    <div>
                        <div className='transition delay-150 duration-300 ease-in-out flex flex-col gap-8'>
                            <div className='flex flex-col gap-4'>
                                <div className='text-center font-bold text-2xl'>Urime fituesve!</div>
                                <div className='text-gray-400 text-center text-xs italic'>Në tabelë janë shfaqur vetëm numrat personal të fituesve për shkak të transparencës</div>
                            </div>
                            <div className='h-[400px] overflow-y-scroll scrollbar-thin scrollbar-thumb scrollbar-track'>
                                {/* <div className='flex flex-col gap-2 p-4'>
                                        {maskUserData(winners).map(winner => (
                                            <div className='flex items-center gap-4'>
                                                <div className='border rounded-lg p-2'><LiaUser size={28} /></div>
                                                <div className='flex w-full justify-between items-center'>
                                                    <div>{winner.name}</div>
                                                    <div>{winner.personal_number}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div> */}
                                <table className='w-full text-md'>
                                    <tr className='border-b font-semibold'>
                                        <td className='p-2 w-[10%]'>#</td>
                                        <td className='p-2 w-[20%]'>Emri</td>
                                        <td className='p-2 w-[25%]'>Mbiemri</td>
                                        <td className='p-2 w-[25%]'>Numri personal</td>
                                        <td className='p-2 w-[20%]'>Çmimi i fituar</td>
                                    </tr>
                                    {data?.map(item => (
                                        <tr className=''>
                                            <>
                                                <td className='p-2 tracking-wide'><LiaUser size={20} /></td>
                                                <td className='p-2 tracking-wide'>{item?.name.split(' ')[0]}</td>
                                                <td className='p-2 tracking-wide'>{item?.name.split(' ')[1]}</td>
                                                <td className='p-2 tracking-wide'>{item?.personal_number}</td>
                                                <td className='p-2 tracking-wide'>50€</td>
                                            </>
                                        </tr>
                                    ))}
                                </table>
                                {/* <div className='flex flex-col gap-4 items-center text-xl'>
                                        {maskUserData(winners)?.map(item => (
                                            <div className='flex items-center gap-4'>
                                                <div className='w-12 h-12 rounded-full border flex items-center justify-center'><BiUser size={24} /></div>
                                                <div>-</div>
                                                <div className='tracking-wider flex'>
                                                    {item.personal_number.split("").map(item => (
                                                        <span className='w-6 h-6 flex items-center justify-center'>{item}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div> */}
                            </div>
                            <div>
                                <div onClick={handleUserDataMask} className='hover:underline cursor-pointer text-sm flex items-center gap-2 text-gray-500'>
                                    {dataMaskedOff && (
                                        <>
                                            <span><GoEye /></span>
                                            <span className='leading-3'>Shfaq të dhënat</span>
                                        </>
                                    )}
                                    {!dataMaskedOff && (
                                        <>
                                            <span><GoEyeClosed /></span>
                                            <span className='leading-3'>Fsheh të dhënat</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='p-2 flex justify-between'>
                                <div className='p-2 px-5 border border-primary text-primary flex items-center gap-4 cursor-pointer rounded-md' onClick={handleNavigate}>
                                    <span><IoMdArrowBack size={18} /></span>
                                    <span>Shko te faqja e fituesve</span>
                                </div>
                                <div className='p-2 px-5 bg-primary text-white flex items-center gap-4 cursor-pointer rounded-md' onClick={() => exportDataToExcel(winners, "Fituesit e lotarisë")}>
                                    <span><FaDownload size={16} /></span>
                                    <span>Shkarko raportin</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
