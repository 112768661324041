import ComplaintsListItem from "./ComplaintsListItem";

export default function ComplaintsList({ items }) {
    return (
        <>
            {items.map(item => (
                <ComplaintsListItem key={item.id} item={item} />
            ))}
        </>
    )
}