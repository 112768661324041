import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const nacApi = createApi({
    reducerPath: "nacApi",
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin`,
    }),
    endpoints: (build) => ({
        getNACCodes: build.query({
            query(params) {
                return {
                    url: "/NAC_codes",
                    method: "GET",
                    params: params
                };
            },
            providesTags: ["Games"],
        }),
    }),
});

export const {
    useGetNACCodesQuery,
    useLazyGetNACCodesQuery
} = nacApi;