import ApprovedPaymentsItem from "./ApprovedPaymentsItem"

export default function ApprovedPaymentsList({ payments }) {
    return (
        <>
            {payments.slice(0, 10).map(payment => (
                <ApprovedPaymentsItem payment={payment} />
            ))}
        </>
    )
}