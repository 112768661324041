import NotificationsListItem from "./NotificationsListItem";

export default function NotificationsList({ notifications }) {
  console.log("🚀 ~ NotificationsList ~ notifications:", notifications);
  return (
    <>
      {notifications.map((notification) => (
        <NotificationsListItem notification={notification} />
      ))}
    </>
  );
}
