import { LuTrash2 } from 'react-icons/lu'
import { tBodyItemStyle } from '../users/UserListItem'
import { FiEdit } from 'react-icons/fi'
import { IoInformation } from 'react-icons/io5'
import { auditLogViewActions } from '../../store/auditLogViewSlice';
import { useDispatch } from 'react-redux';
import { MdHistory } from "react-icons/md";

export const getActionStyle = (status) => {
    if (status) {
        switch (status.toLowerCase()) {
            case 'create':
                return 'text-gray-500';
            case 'update':
                return 'text-blue-700';
            case 'submit':
                return 'text-black';
            case 'soft delete':
                return 'text-red-700';
            case 'approved':
                return 'text-green-700';
            default:
                return 'text-black';
        }
    }
};

export default function AnalyticsListItem({ item }) {

    const dispatch = useDispatch()

    return (
        <>
            <tr className='table_row' onClick={() => dispatch(auditLogViewActions.openModal(item))}>
                <td style={tBodyItemStyle} className='w-[5%]'><MdHistory size={24} color='black' /></td>
                <td style={tBodyItemStyle} className='text-black font-medium w-[10%]'>{item.id}</td>
                <td style={tBodyItemStyle} className='w-[15%]'>{new Date(item.created_at).toLocaleString("en-GB", { timeZone: "UTC" })}</td>
                <td style={tBodyItemStyle} className={`font-semibold text-black w-[15%]`}>
                    <span className={``}>{item.action_type}</span>
                </td>
                <td style={tBodyItemStyle} className='text-black font-medium w-[15%]'>
                    {item.table_name === 'pending_receipts' && <span>Pending Receipts</span>}
                    {item.table_name === 'submitted_receipts' && <span>Submitted Receipts</span>}
                    {item.table_name === 'users' && <span>Users</span>}
                    {item.table_name === 'notifications' && <span>Notifications</span>}
                </td>
                <td style={tBodyItemStyle} className='text-black font-medium w-[15%]'>{item.name}</td>
                <td style={tBodyItemStyle} className='text-black font-medium w-[5%]'>{item.user_role}</td>
                <td style={tBodyItemStyle} className="w-[10%]">
                    <div className="flex justify-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions info-action" title="Get info">
                            <IoInformation style={{ fontSize: '20px', textAlign: 'center' }} />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )
}