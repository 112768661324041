import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { enUS, et } from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Modal from 'react-modal'
import * as XLSX from "xlsx";
import { IoClose } from "react-icons/io5";
import { UsersFilters } from "./UsersFilters";
import { ReceiptsFilters } from "./ReceiptsFilters";
import { WinnersFilters } from "./WinnersFilters";
import { inputStyle, labelStyle } from "./utils";

Modal.setAppElement(document.getElementById('root'))

const customStyles = {
    content: {
        position: 'static',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

const DropdownMultiSelectInput = () => {

}

const FilterPopUp = ({ open, setIsOpen, onClose, onConfirm, handleFormSubmit, category, params }) => {

    const [dropdownSelectItems, setDropdownSelectItems] = useState({
        game_id: [],
        tier_level: []
    })

    const [dropdownMultiSelectItems, setDropdownMultiSelectItems] = useState({
        zip_codes: [],
    })

    const [rangeItems, setRangeItems] = useState({
        start_num_receipts: "",
        end_num_receipts: "",
        start_sum_with_vat: "",
        end_sum_with_vat: "",
        start_sum_without_vat: "",
        end_sum_without_vat: "",
    })

    const [multiSelectItems, setMultiSelectItems] = useState({
        personal_numbers: "",
        bank_account_numbers: "",
        bank_ids: "",
        zip_codes: "",
        NUIs: "",
        fiscal_receipt_numbers: "",
        fiscal_device_serial_numbers: ""
    })

    const [multiSelectFilters, setMultiSelectFilters] = useState({
        personal_numbers: [],
        bank_account_numbers: [],
        bank_ids: [],
        NUIs: [],
        fiscal_receipt_numbers: [],
        fiscal_device_serial_numbers: []
    })

    const [selectedCategory, setSelectedCategory] = useState('users')


    const [selectedNUIs, setSelectedNUIs] = useState([])

    const [filters, setFilters] = useState({})

    const [selectedFilters, setSelectedFilters] = useState([]);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const [dateRange, setDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection",
        },
    ]);

    const [file, setFile] = useState(null)
    const [excelData, setExcelData] = useState([]);

    const calendarRef = useRef()

    useEffect(() => {
        setSelectedCategory(category)
    }, [category])

    useEffect(() => {
        console.log('params passing in popup', params)

        let rangeParams = {}
        let multiSelectParams = {}
        let dropdownParams = {}
        let dropdownSingleParams = {}

        const start_num_receipts = params['start_num_receipts']
        if (start_num_receipts) rangeParams['start_num_receipts'] = start_num_receipts
        const end_num_receipts = params['end_num_receipts']
        if (end_num_receipts) rangeParams['end_num_receipts'] = end_num_receipts
        const start_sum_with_vat = params['start_sum_with_vat']
        if (start_sum_with_vat) rangeParams['start_sum_with_vat'] = start_sum_with_vat
        const end_sum_with_vat = params['end_sum_with_vat']
        if (end_sum_with_vat) rangeParams['end_sum_with_vat'] = end_sum_with_vat
        const start_sum_without_vat = params['start_sum_without_vat']
        if (start_sum_without_vat) rangeParams['start_sum_without_vat'] = start_sum_without_vat
        const end_sum_without_vat = params['end_sum_without_vat']
        if (end_sum_without_vat) rangeParams['end_sum_without_vat'] = end_sum_without_vat

        const personal_numbers = params['personal_numbers[]']
        if (personal_numbers) {
            if (Array.isArray(personal_numbers)) {
                multiSelectParams['personal_numbers'] = personal_numbers
            }
            else {
                multiSelectParams['personal_numbers'] = [personal_numbers]
            }
        }
        const bank_account_numbers = params['bank_account_numbers[]']
        if (bank_account_numbers) {
            if (Array.isArray(bank_account_numbers)) {
                multiSelectParams['bank_account_numbers'] = bank_account_numbers
            }
            else {
                multiSelectParams['bank_account_numbers'] = [bank_account_numbers]
            }
        }
        const bank_ids = params['bank_ids[]']
        if (bank_ids) {
            if (Array.isArray(bank_ids)) {
                multiSelectParams['bank_ids'] = bank_ids
            }
            else {
                multiSelectParams['bank_ids'] = [bank_ids]
            }
        }
        const NUIs = params['NUIs[]']
        if (NUIs) {
            if (Array.isArray(NUIs)) {
                multiSelectParams['NUIs'] = NUIs
            }
            else {
                multiSelectParams['NUIs'] = [NUIs]
            }
        }
        const fiscal_receipt_numbers = params['fiscal_receipt_numbers[]']
        if (fiscal_receipt_numbers) {
            if (Array.isArray(fiscal_receipt_numbers)) {
                multiSelectParams['fiscal_receipt_numbers'] = fiscal_receipt_numbers
            }
            else {
                multiSelectParams['fiscal_receipt_numbers'] = [fiscal_receipt_numbers]
            }
        }
        const fiscal_device_serial_numbers = params['fiscal_device_serial_numbers[]']
        if (fiscal_device_serial_numbers) {
            if (Array.isArray(fiscal_device_serial_numbers)) {
                multiSelectParams['fiscal_device_serial_numbers'] = fiscal_device_serial_numbers
            }
            else {
                multiSelectParams['fiscal_device_serial_numbers'] = [fiscal_device_serial_numbers]
            }
        }

        const zip_code = params['zip_codes[]']
        if (zip_code) {
            if (Array.isArray(zip_code)) {
                dropdownParams['zip_codes'] = zip_code
            }
            else {
                dropdownParams['zip_codes'] = [zip_code]
            }
        }

        const game_id = params['game_id']
        if (game_id) {
            dropdownSingleParams['game_id'] = game_id
        }
        const tier_level = params['tier_level']
        if (tier_level) {
            dropdownSingleParams['tier_level'] = tier_level
        }

        setRangeItems(rangeParams)
        setMultiSelectFilters(multiSelectParams)
        setDropdownMultiSelectItems(dropdownParams)
        setDropdownSelectItems(dropdownSingleParams)
    }, [params])

    const clearFilters = () => {
        setRangeItems({
            personal_numbers: "",
            bank_account_numbers: "",
            bank_ids: "",
            zip_codes: "",
            NUIs: "",
            fiscal_receipt_numbers: "",
            fiscal_device_serial_numbers: ""
        })
        setMultiSelectFilters({
            personal_numbers: [],
            bank_account_numbers: [],
            bank_ids: [],
            NUIs: [],
            fiscal_receipt_numbers: [],
            fiscal_device_serial_numbers: []
        })
        setDropdownMultiSelectItems({
            zip_codes: [],
        })
        setDropdownSelectItems({
            game_id: [],
            tier_id: []
        })
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setIsCalendarOpen(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsCalendarOpen]);

    const selectedStartDate = dateRange[0].startDate
        ? dateRange[0].startDate.toLocaleDateString("en-GB")
        : null;

    const selectedEndDate = dateRange[0].endDate
        ? dateRange[0].endDate.toLocaleDateString("en-GB")
        : null;

    const handleDropdownMultiSelectChange = (selectedOptions, actionMeta) => {
        const category = actionMeta.name;
        const values = selectedOptions.map(option => option.value)
        setDropdownMultiSelectItems({ ...dropdownMultiSelectItems, [category]: values })
    }

    const handleDropdownSelectChange = (selectedOption, actionMeta) => {
        const category = actionMeta.name
        setDropdownSelectItems({ ...dropdownSelectItems, [category]: selectedOption.value })
    }

    useEffect(() => {
        console.log({ dropdownSelectItems })
    }, [dropdownSelectItems])

    const handleMultiSelectFiltersChange = (e) => {
        e.preventDefault();
        const category = e.target.elements[0].name
        const itemState = multiSelectItems[category] || ""
        const filterState = multiSelectFilters[category] || []
        if (filterState.includes(itemState)) {
            const updated = filterState.filter(item => item !== itemState)
            setMultiSelectFilters({ ...multiSelectFilters, [category]: updated })
            return;
        }
        if (itemState?.length <= 0) {
            return;
        }
        setMultiSelectFilters({ ...multiSelectFilters, [category]: [...filterState, itemState] })
        setMultiSelectItems({ ...multiSelectItems, [category]: "" })
    }

    const handleDeleteMultiSelectFilters = (category, value) => {
        setMultiSelectFilters({ ...multiSelectFilters, [category]: [...multiSelectFilters[category].filter(item => item !== value)] })
    }

    const handleMultiSelectItemsChange = (e) => {
        setMultiSelectItems({ ...multiSelectItems, [e.target.name]: e.target.value })
    }

    const handleRangeItemsChange = (e) => {
        setRangeItems({ ...rangeItems, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        const data = { ...multiSelectFilters, ...rangeItems, ...dropdownMultiSelectItems, ...dropdownSelectItems, start_date: dateRange[0].startDate && dateRange[0].startDate.toISOString(), end_date: dateRange[0].endDate && dateRange[0].endDate.toISOString() }
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(
                ([_, value]) => !(value === "" || (Array.isArray(value) && value.length === 0) || value == null)
            )
        );
        handleFormSubmit(selectedCategory, filteredData)
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: "binary" });

                const sheetName = workbook.SheetNames[0];

                const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                    header: 1,
                });

                const headerIndex = sheetData.findIndex(
                    (row) => Array.isArray(row) && row.includes("Numri personal") || row.includes("Numri i bankes") || row.includes("NUI") || row.includes("Numri fiskal") || row.includes("Numri serik")
                );

                if (headerIndex !== -1) {
                    const personalNumberCol = sheetData[headerIndex].indexOf("Numri personal");
                    const bankNumberCol = sheetData[headerIndex].indexOf("Numri i bankes");
                    const NUICol = sheetData[headerIndex].indexOf("NUI");
                    const fiscalNumberCol = sheetData[headerIndex].indexOf("Numri fiskal");
                    const deviceSerialNumberCol = sheetData[headerIndex].indexOf("Numri serik");

                    const dataRows = sheetData.slice(headerIndex + 1);

                    const personalNumbers = dataRows.map((row) => row[personalNumberCol]).filter(Boolean); // Filter out empty values
                    const bankNumbers = dataRows.map((row) => row[bankNumberCol]).filter(Boolean); // Filter out empty values
                    const NUINumbers = dataRows.map((row) => row[NUICol]).filter(Boolean); // Filter out empty values
                    const fiscalNumbers = dataRows.map((row) => row[fiscalNumberCol]).filter(Boolean); // Filter out empty values
                    const deviceSerialNumbers = dataRows.map((row) => row[deviceSerialNumberCol]).filter(Boolean); // Filter out empty values

                    const updatedMultiSelectFilters = {
                        ...multiSelectFilters,
                        personal_numbers: [...multiSelectFilters['personal_numbers'] || [], ...personalNumbers.map(String)],
                        bank_account_numbers: [...multiSelectFilters['bank_account_numbers'] || [], ...bankNumbers.map(String)],
                        NUIs: [...multiSelectFilters['NUIs'] || [], ...NUINumbers.map(String)],
                        fiscal_receipt_numbers: [...multiSelectFilters['fiscal_receipt_numbers'] || [], ...fiscalNumbers.map(String)],
                        fiscal_device_serial_numbers: [...multiSelectFilters['fiscal_device_serial_numbers'] || [], ...deviceSerialNumbers.map(String)],
                    }

                    setMultiSelectFilters(updatedMultiSelectFilters)

                } else {
                    console.log(headerIndex)
                    console.error("Headers not found in the sheet");
                }

                // Update state with the extracted data
                // setExcelData(formattedData);
                // console.log("Extracted Data:", formattedData);
            };

            reader.readAsBinaryString(file);
        }
    }

    const handleCancelClick = () => {
        onClose();
    }

    return (
        <Modal
            isOpen={open}
            style={customStyles}
            onRequestClose={onClose}
        >
            <div className="bg-white rounded-lg w-[600px] h-[800px] overflow-y-scroll scrollbar-thin scrollbar-track scrollbar-thumb backface-hidden translate-z-0 relative">
                <div className="p-4 flex flex-col gap-1 justify-between">
                    <div className="flex justify-end">
                        <div onClick={handleCancelClick}><IoClose size={24} /></div>
                    </div>
                    <div className="w-full flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                            <label htmlFor="category" className={labelStyle}>Kategoria</label>
                            <select name="category" id="category" className={inputStyle} onChange={(e) => setSelectedCategory(e.target.value)} defaultValue={selectedCategory}>
                                <option value="users">Users</option>
                                <option value="receipts">Receipts</option>
                                <option value="winners">Winners</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-1">
                            <input name="file" id="file" type="file" accept=".xls, .xlsx" onChange={handleFileChange} className="hidden" />
                            <label htmlFor="file">
                                <div className="w-full p-2 bg-white rounded-xl border-2 border-dashed border-gray-200 flex justify-center cursor-pointer">
                                    <div className="my-4 flex flex-col gap-4 items-center text-sm">
                                        <div className="text-appgray text-center">
                                            <span className="text-primary font-semibold cursor-pointer">Kliko per te ngarkuar</span> ose tërhiqni dhe lëshoni
                                        </div>
                                        <div className="p-2 px-4 bg-primary w-max flex text-center text-white rounded-lg font-semibold">Ngarko fajllin</div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        {selectedCategory === 'users' && (
                            <UsersFilters
                                multiSelectFilters={multiSelectFilters}
                                handleMultiSelectFiltersChange={handleMultiSelectFiltersChange}
                                multiSelectItems={multiSelectItems}
                                handleMultiSelectItemsChange={handleMultiSelectItemsChange}
                                rangeItems={rangeItems}
                                handleRangeItemsChange={handleRangeItemsChange}
                                dropdownMultiSelectItems={dropdownMultiSelectItems}
                                handleDropdownMultiSelectChange={handleDropdownMultiSelectChange}
                                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
                            />
                        )}
                        {selectedCategory === 'receipts' && (
                            <ReceiptsFilters
                                multiSelectFilters={multiSelectFilters}
                                handleMultiSelectFiltersChange={handleMultiSelectFiltersChange}
                                multiSelectItems={multiSelectItems}
                                handleMultiSelectItemsChange={handleMultiSelectItemsChange}
                                rangeItems={rangeItems}
                                handleRangeItemsChange={handleRangeItemsChange}
                                dropdownMultiSelectItems={dropdownMultiSelectItems}
                                handleDropdownMultiSelectChange={handleDropdownMultiSelectChange}
                                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
                            />
                        )}
                        {selectedCategory === 'winners' && (
                            <WinnersFilters
                                multiSelectFilters={multiSelectFilters}
                                handleMultiSelectFiltersChange={handleMultiSelectFiltersChange}
                                multiSelectItems={multiSelectItems}
                                handleMultiSelectItemsChange={handleMultiSelectItemsChange}
                                rangeItems={rangeItems}
                                handleRangeItemsChange={handleRangeItemsChange}
                                dropdownMultiSelectItems={dropdownMultiSelectItems}
                                handleDropdownMultiSelectChange={handleDropdownMultiSelectChange}
                                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
                                dropdownSelectItems={dropdownSelectItems}
                                handleDropdownSelectChange={handleDropdownSelectChange}
                            />
                        )}
                        <div className="w-full flex flex-col gap-1 relative">
                            <label className={labelStyle}>Data</label>
                            <div className="w-full flex gap-4">
                                <input name="start_date" id="start_date" value={selectedStartDate} className={inputStyle} placeholder="Nga" onFocus={() => setIsCalendarOpen(true)} />
                                <input name="end_date" id="end_date" value={selectedEndDate} disabled className={inputStyle} placeholder="Deri" />
                            </div>
                            {isCalendarOpen && (
                                <div className="report-date-range absolute z-40 bottom-14" ref={calendarRef}>
                                    <DateRangePicker
                                        ranges={dateRange}
                                        direction="horizontal"
                                        locale={enUS}
                                        onChange={(item) => setDateRange([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        showSelectionPreview={false}
                                        staticRanges={[]}
                                        inputRanges={[]}
                                        className="mt-4"
                                        rangeColors={["#bbb"]}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="sticky bottom-0 border-t bg-white w-full flex justify-between p-4">
                    <div className={`p-2 bg-white rounded-lg border cursor-pointer w-max px-8`} onClick={clearFilters}>
                        Reset
                    </div>
                    <div className="flex items-center gap-4">
                        <div className={`p-2 bg-white rounded-lg border cursor-pointer w-max px-8`} onClick={handleCancelClick}>
                            Anulo
                        </div>
                        <div className={`p-2 bg-primary rounded-lg text-white cursor-pointer w-max px-8`} onClick={handleSubmit}>
                            Vazhdo
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default FilterPopUp;