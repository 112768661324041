import { Link } from "react-router-dom"

export const MenuItem = ({ category, title, icon, linkTo, handleClick, activeCategory, isBigMenu }) => {
    return (
        <div category={category} onClick={handleClick} title={title}>
            <Link to={linkTo} className="w-full flex gap-4 items-center cursor-pointer">
                <div className={`p-[12px] border border-white rounded-xl ${activeCategory === category ? 'bg-primary border-primary text-white' : 'text-gray-400 hover:bg-gray-100'}`}>
                    {icon}
                </div>
                {isBigMenu && <div className="text-sm font-semibold">{title}</div>}
            </Link>
        </div>
    )
}