import { useParams } from "react-router"
import ApprovedPayments from "./prizes/ApprovedPayments";
import { useEffect } from "react";
import { menuActions } from "../store/menuSlice";
import { useDispatch } from "react-redux";
import ApprovedReceipts from "./receipts/ApprovedReceipts";

export default function ApprovedComponent() {

    const { category } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(menuActions.changeActiveCategory({ category: category }))
    }, [dispatch, category])

    return (
        <>
            {category === 'receipts' && <ApprovedReceipts />}
            {category === 'payments' && <ApprovedPayments />}
        </>
    )
}