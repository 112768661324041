import { FiUsers } from "react-icons/fi";
import { IoRibbonOutline } from "react-icons/io5";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { useGetInformationQuery } from "../store/services/homepageServices";
import { useNavigate } from "react-router";
import { HiOutlineDocumentMinus } from "react-icons/hi2";

export default function Homepage() {

    const { data } = useGetInformationQuery()
    const navigate = useNavigate()

    console.log({ data })

    const itemStyle = 'flex flex items-center gap-8 overflow-hidden justify-center w-full h-full bg-white rounded-xl p-8 border shadow-lg hover:scale-[1.02] cursor-pointer transition-all'
    const iconStyle = 'w-28 h-28 flex items-center text-[#D1AB66] justify-center border border-[#D1AB66] rounded-full'

    return (
        <div className="p-16 h-full">
            <div className="w-full h-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
                <div className={itemStyle} onClick={() => navigate('/submitted_receipts') }>
                    <div className={iconStyle}>
                        <HiOutlineDocumentText size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">{data?.total_receipts}</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts submitted</div>
                    </div>
                </div>
                <div className={itemStyle} onClick={() => navigate('/approved_receipts') }>
                    <div className={iconStyle}>
                        <HiOutlineDocumentCheck size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">{data?.total_approved_receipts}</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts approved</div>
                    </div>
                </div>
                <div className={itemStyle}>
                    <div className={iconStyle}>
                        <HiOutlineDocumentMinus size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">{data?.total_rejected_receipts}</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts rejected</div>
                    </div>
                </div>
                <div className={`${itemStyle} xl:col-span-2 xl:row-span-2`}>
                    <div className={iconStyle}>
                        <HiOutlineDocumentCheck size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">{data?.total_previous_approved_receipts}</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Receipts approved in the last month</div>
                    </div>
                </div>
                <div className={itemStyle} onClick={() => navigate('/prizes') }>
                    <div className={iconStyle}>
                        <IoRibbonOutline size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">5</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Prizes awarded</div>
                    </div>
                </div>
                <div className={itemStyle} onClick={() => navigate('/users') }>
                    <div className={iconStyle}>
                        <HiOutlineDocumentText size={60} />
                    </div>
                    <div>
                        <div className="text-[40px] font-bold leading-10">{data?.total_registered_users}</div>
                        <hr className="my-2" />
                        <div className="text-gray-800">Users registered</div>
                    </div>
                </div>
            </div>
        </div >
    )
}