import { useNavigate, useParams } from "react-router-dom";
import UserDashboard from "./users/UserDashboard";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { menuActions } from "../store/menuSlice";
import ReceiptDashboard from "./receipts/ReceiptsMain";
import NotificationsMain from "./notifications/NotificationsMain";
import AnalyticsMain from "./analytics/AnalyticsMain";
import PerformanceMain from "./sub_property_types/PerformanceMain";
import PrizesMain from "./prizes/PrizesMain";
import ApprovedReceipts from "./receipts/ApprovedReceipts";
import ComplaintsMain from "./complaints/ComplaintsMain";
import ReportsMain from "./reports/ReportsMain";
import { NonFiscalReceipts } from "./receipts/non_fiscal_receipts/NonFiscalReceipts";
import { GenerateWinners } from "./GenerateWinners";
import GameRulesTable from "./game_rules/GameRulesTable";

export default function CategoryComponent() {
  const { category } = useParams(); // category will be 'users' for '/users'

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!category) {
      navigate("/users");
      dispatch(menuActions.changeActiveCategory({ category: "users" }));
      return;
    }
    dispatch(menuActions.changeActiveCategory({ category: category }));
  }, [dispatch, category, navigate]);

  return (
    <>
      {category === "users" && <UserDashboard />}
      {category === "submitted_receipts" && <ReceiptDashboard />}
      {category === "approved_receipts" && <ApprovedReceipts />}
      {category === "non_fiscal_receipts" && <NonFiscalReceipts />}
      {category === "winners" && <PrizesMain />}
      {category === "prizes" && <GenerateWinners />}
      {category === "games" && <GameRulesTable />}
      {category === "notifications" && <NotificationsMain />}
      {category === "reports" && <ReportsMain />}
      {category === "complaints" && <ComplaintsMain />}
      {category === "audit_logs" && <AnalyticsMain />}
      {category === "performance" && <PerformanceMain />}
    </>
  );
}
