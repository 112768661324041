import { IoMdAddCircleOutline } from "react-icons/io"
import { IoClose } from "react-icons/io5"
import * as XLSX from 'xlsx';
import { jsPDF } from "jspdf";

export const inputStyle = 'p-3 border border-gray-300 rounded-lg w-full'
export const labelStyle = 'text-sm font-medium text-gray-800'

export const MultiSelectInput = ({ type, name, label, placeholder, value, handleValueChange, handleSelectedValuesChange, selectedValues, handleDeleteMultiSelectFilters }) => {
    return (
        <form onSubmit={handleSelectedValuesChange}>
            <div className="flex flex-col gap-2">
                <label htmlFor={name} className={labelStyle}>{label}:</label>
                <div className="w-full flex gap-2 items-center">
                    <div className="w-[80%]">
                        <input value={value} onChange={handleValueChange} name={name} id={name} type={type} className={inputStyle} placeholder={placeholder} onWheel={(e) => e.target.blur()} min="0" />
                    </div>
                    <div className="w-[20%]">
                        <button name={name} type="submit" className={`${inputStyle} text-center bg-primary text-white cursor-pointer flex items-center justify-center gap-2`}>
                            <span><IoMdAddCircleOutline size={20} /></span>
                            <span>Shto</span>
                        </button>
                    </div>
                </div>
                {selectedValues?.length > 0 && (
                    <div className="flex flex-wrap mt-1 gap-2 items-center">
                        {selectedValues.map((item, index) => <div name={name} key={index} className="p-[2px] px-2 text-xs font-semibold flex items-center gap-1 rounded-full leading-5 text-gray-500 border border-gray-300 w-max">
                            <span>{item}</span>
                            <span className="cursor-pointer hover:text-red-600" onClick={() => handleDeleteMultiSelectFilters(name, item)}><IoClose size={14} /></span>
                        </div>)}
                    </div>
                )}
            </div>
        </form>
    )
}

export const RangeInput = ({ type, start_name, end_name, label, start_placeholder, end_placeholder, start_value, end_value, handleValueChange }) => {
    return (
        <div className="flex flex-col gap-1">
            <label className={labelStyle}>{label}</label>
            <div className="flex w-full gap-4">
                <input name={start_name} id={start_name} type={type} value={start_value} onChange={handleValueChange} className={inputStyle} placeholder={start_placeholder} onWheel={(e) => e.target.blur()} min={0} />
                <input name={end_name} id={end_name} type={type} value={end_value} onChange={handleValueChange} className={inputStyle} placeholder={end_placeholder} onWheel={(e) => e.target.blur()} min={0} />
            </div>
        </div>
    )
}


export const exportDataToExcel = (data, title, filters = {}) => {

    console.log({ filters });

    let description = "Filterat e aplikuar: \n";

    const propertyMap = {
        personal_numbers: (val) => `- numri personal: ${val.join(', ')}\n`,
        bank_account_numbers: (val) => `- numri i bankes: ${val.join(', ')}\n`,
        zip_codes: (val) => `- kod postar: ${val.join(', ')}\n`,
        NUIs: (val) => `NUI: ${val.join(', ')}\n`,
        fiscal_receipt_numbers: (val) => `- numri fiskal i faturave: ${val.join(', ')}\n`,
        fiscal_device_serial_numbers: (val) => `- numri serik i faturave: ${val.join(', ')}\n`,
        start_num_receipts: (val) => `- numri i faturave: nga ${val} `,
        end_num_receipts: (val, startNumReceipts) => {
            if (startNumReceipts) {
                return `deri ne ${val}\n`;
            }
            return `- numri i faturave: deri ne ${val}\n`; // or any fallback value
        },
        start_sum_with_vat: (val) => `- shuma totale me TVSH: nga ${val}€`,
        end_sum_with_vat: (val, startSumWithVat) => {
            if (startSumWithVat) {
                return `deri ne ${val}\n€`;
            }
            return `- shume totale me TVSH: deri ne ${val}€\n`; // or any fallback value
        },
        start_sum_without_vat: (val) => `- shuma totale pa TVSH: nga ${val}€ `,
        end_sum_without_vat: (val, startSumWithoutVat) => {
            if (startSumWithoutVat) {
                return `deri ne ${val}€\n`;
            }
            return `- shume totale pa TVSH: deri ne ${val}€\n`; // or any fallback value
        }
    };

    for (const key in filters) {
        if (Object.hasOwn(filters, key) && propertyMap[key]) {
            if ((key === 'end_num_receipts')) {
                description += propertyMap[key](filters[key], filters['start_num_receipts']);
            }
            else if ((key === 'end_sum_with_vat')) {
                description += propertyMap[key](filters[key], filters['start_sum_with_vat']);
            }
            else if ((key === 'end_sum_without_vat')) {
                description += propertyMap[key](filters[key], filters['start_sum_without_vat']);
            }
            else {
                description += propertyMap[key](filters[key]);
            }
        }
    }

    console.log({ description });

    // Initialize worksheet
    const ws = XLSX.utils.json_to_sheet([]);

    let dataStartRow = 1; // Default starting row

    if (description && typeof description === "string" && description.trim() !== "") {
        // Add description to B2 and below (leave B1 empty)
        const descriptionLines = description.split("\n").filter(line => line.trim() !== "");
        descriptionLines.forEach((line, index) => {
            XLSX.utils.sheet_add_aoa(ws, [[line]], { origin: { r: index + 1, c: 1 } });
        });

        // Leave one row space after description
        dataStartRow = descriptionLines.length + 2;
    }

    // Add headers and data only if there's data
    if (data && data.length > 0) {
        // Add headers at dataStartRow
        const columnKeys = Object.keys(data[0] || {});
        XLSX.utils.sheet_add_aoa(ws, [columnKeys], { origin: { r: dataStartRow - 1, c: 1 } });

        // Add data starting at dataStartRow + 1
        XLSX.utils.sheet_add_json(ws, data, {
            origin: { r: dataStartRow, c: 1 },
            skipHeader: true,
        });

        // Set column widths dynamically
        const colWidths = columnKeys.map((key) => {
            const maxLength = Math.max(
                key.length,
                ...data.map(row => (row[key] ? row[key].toString().length : 0))
            );
            return { wch: maxLength + 2 };
        });
        ws["!cols"] = [{ wch: 2 }, ...colWidths];

        // Style headers
        columnKeys.forEach((key, colIndex) => {
            const cellAddress = XLSX.utils.encode_cell({ r: dataStartRow - 1, c: colIndex + 1 });
            if (ws[cellAddress]) {
                ws[cellAddress].s = {
                    font: { bold: true },
                    alignment: { horizontal: "center" },
                    fill: { fgColor: { rgb: "FFFFCC" } },
                };
            }
        });
    } else {
        // Handle case where there's no data
        XLSX.utils.sheet_add_aoa(ws, [['No data available']], { origin: { r: dataStartRow, c: 1 } });
    }

    // Create workbook and save file
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, title);
    XLSX.writeFile(wb, `${title}.xlsx`);
};

export const exportToPdf = (data) => {
    const pdf = new jsPDF();

    console.log('pdf data', { data })

    let yPosition = 10;

    data.forEach((item, index) => {
        pdf.setFontSize(14);
        pdf.text(`Item ${index + 1}: ${item.name}`, 10, yPosition);

        yPosition += 10;

        Object.entries(item).forEach(([key, value], i) => {
            pdf.setFontSize(12);
            pdf.text(`${key}: ${value}`, 10, yPosition);

            yPosition += 10;
        });

        yPosition += 5;
    });

    pdf.save("exported_data.pdf");
};