import { FaDownload } from "react-icons/fa";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import { HiOutlineHashtag } from "react-icons/hi";

const UsersModal = ({ isOpen, handleClose, users }) => {
  if (!isOpen) return null;

  const exportDataToExcel = () => {
    if (!users || users.length === 0) {
      alert("Nuk ka të dhëna për të gjeneruar");
      return;
    }

    const formattedUsers = users.map((user, index) => ({
      "#": index + 1,
      ID: user.id,
      Emri: user.name || "N/A",
      "Numri Personal": user.personal_number || "N/A",
      Email: user.email || "N/A",
      Telefoni: user.phone_number || "N/A",
      Ditelindja: user.birthdate ? user.birthdate.split("T")[0] : "N/A",
      "Zip kodi": user.zip_code || "N/A",
      "Numri i bankes": user.bank_account_number || "N/A",
      "Emri i bankes": user.bank_name || "N/A",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedUsers);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Fituesit");

    XLSX.writeFile(wb, "NumriIFituesve_GIZ_Dashboard.xlsx");
  };

  const exportToPdf = () => {
    if (!users || users.length === 0) {
      alert("Nuk ka të dhëna për të gjeneruar");
      return;
    }

    const pdf = new jsPDF();
    pdf.setFontSize(16);
    pdf.text("Lista e Fituesve", 14, 10);

    let yPosition = 20;
    users.forEach((user, index) => {
      pdf.setFontSize(12);
      pdf.text(`${index + 1}. ID: ${user.id}`, 10, yPosition);
      yPosition += 6;
      pdf.text(`   Emri: ${user.name || "N/A"}`, 10, yPosition);
      yPosition += 6;
      pdf.text(
        `   Numri Personal: ${user.personal_number || "N/A"}`,
        10,
        yPosition
      );
      yPosition += 6;
      pdf.text(`   Email: ${user.email || "N/A"}`, 10, yPosition);
      yPosition += 6;
      pdf.text(`   Telefoni: ${user.phone_number || "N/A"}`, 10, yPosition);
      yPosition += 6;
      pdf.text(
        `   Ditelindja: ${user.birthdate ? user.birthdate.split("T")[0] : "N/A"
        }`,
        10,
        yPosition
      );
      yPosition += 6;
      pdf.text(`   Zip Kodi: ${user.zip_code || "N/A"}`, 10, yPosition);
      yPosition += 6;
      pdf.text(
        `   Numri i bankes: ${user.bank_account_number || "N/A"}`,
        10,
        yPosition
      );
      yPosition += 6;
      pdf.text(`   Emri i bankes: ${user.bank_name || "N/A"}`, 10, yPosition);
      yPosition += 10;
    });

    pdf.save("Fituesit_GIZ_Dashboard.pdf");
  };

  return (
    <div
      className="fixed z-10 inset-0 bg-black bg-opacity-50 flex justify-center items-center"
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
        <div className="flex justify-between">
          <h2 className="text-xl font-medium mb-4">
            Lojtarët që kanë fituar kushtin
          </h2>
          <div onClick={handleClose} className="cursor-pointer">
            <p>X</p>
          </div>
        </div>
        {users.length > 0 ? (
          <div className="flex gap-1 items-center w-full max-h-[400px] overflow-y-scroll scrollbar-thin scrollbar-thumb scrollbar-track">
            <table className="w-full ">
              <tr className="p-2">
                <th className="text-left p-2">Emri dhe mbiemri</th>
                <th className="text-left p-2">Numri personal</th>
                <th className="text-left p-2">Email</th>
                <th className="text-left p-2">Numri i telefonit</th>
              </tr>
              {users.map((user) => (
                <tr key={user.id} className="p-2">
                  <td className="text-black font-normal p-2">{user.name}</td>
                  <td className="text-black font-normal p-2">{user.personal_number}</td>
                  <td className="text-black font-normal p-2">{user.email}</td>
                  <td className="text-black font-normal p-2">{user.phone_number}</td>
                </tr>
              ))}
            </table>
          </div>
        ) : (
          <div className="italic text-sm text-gray-400">Nuk ka fitues potencial për këtë nivel</div>
        )}
        <div className="mt-4 flex gap-3 items-center">
          <button
            onClick={exportDataToExcel}
            className="flex gap-3 w-1/2 items-center justify-center bg-white text-black border border-gray-300 px-4 py-2 rounded-md"
          >
            <FaDownload size={16} /> Shkarko Excel
          </button>
          <button
            onClick={exportToPdf}
            className="flex gap-3 w-1/2 items-center justify-center bg-primary text-white px-4 py-2 rounded-md"
          >
            <FaDownload size={16} /> Shkarko PDF
          </button>
        </div>
      </div>
    </div >
  );
};

export default UsersModal;
