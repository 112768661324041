import { createSlice } from "@reduxjs/toolkit";

const initialNotificationViewState = { data: {}, isOpen: false };

const notificationViewSlice = createSlice({
  name: "notificationViewSlice",
  initialState: initialNotificationViewState,
  reducers: {
    openModal(state, action) {
      state.data = action.payload;
      state.isOpen = true;
    },
    closeModal(state) {
      state.data = {};
      state.isOpen = false;
    },
  },
});

export const notificationViewActions = notificationViewSlice.actions;
export default notificationViewSlice;
