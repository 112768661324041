import { inputStyle, labelStyle, MultiSelectInput, RangeInput } from "./utils"
import { cities } from "../notifications/AddNotification"
import Select from 'react-select';

export const ReceiptsFilters = ({ multiSelectFilters, handleMultiSelectFiltersChange, multiSelectItems, handleMultiSelectItemsChange, rangeItems, handleRangeItemsChange, dropdownMultiSelectItems, handleDropdownMultiSelectChange, handleDeleteMultiSelectFilters }) => {

    const { NUIs, fiscal_receipt_numbers, fiscal_device_serial_numbers } = multiSelectItems
    const { start_num_receipts, end_num_receipts, start_sum_with_vat, end_sum_with_vat, start_sum_without_vat, end_sum_without_vat } = rangeItems
    const { zip_codes } = dropdownMultiSelectItems
    const { NUIs: selectedNUIs, fiscal_receipt_numbers: selectedFiscalReceiptNumbers, fiscal_device_serial_numbers: selectedFiscalDeviceSerialNumbers } = multiSelectFilters

    return (
        <div className="flex flex-col gap-4">
            <MultiSelectInput
                type="number"
                name="NUIs"
                label="NUI"
                placeholder="Kerko sipas NUI"
                value={NUIs}
                handleValueChange={handleMultiSelectItemsChange}
                handleSelectedValuesChange={handleMultiSelectFiltersChange}
                selectedValues={selectedNUIs}
                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
            />
            <MultiSelectInput
                type="number"
                name="fiscal_receipt_numbers"
                label="Numri fiskal"
                placeholder="Kerko sipas numrit fiskal"
                value={fiscal_receipt_numbers}
                handleValueChange={handleMultiSelectItemsChange}
                handleSelectedValuesChange={handleMultiSelectFiltersChange}
                selectedValues={selectedFiscalReceiptNumbers}
                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
            />
            <MultiSelectInput
                type="number"
                name="fiscal_device_serial_numbers"
                label="Numri serik"
                placeholder="Kerko sipas numrit serik"
                value={fiscal_device_serial_numbers}
                handleValueChange={handleMultiSelectItemsChange}
                handleSelectedValuesChange={handleMultiSelectFiltersChange}
                selectedValues={selectedFiscalDeviceSerialNumbers}
                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
            />
            <RangeInput
                type="number"
                start_name="start_sum_with_vat"
                end_name="end_sum_with_vat"
                label="Shuma totale me TVSH"
                start_placeholder="Nga"
                end_placeholder="Deri"
                start_value={start_sum_with_vat}
                end_value={end_sum_with_vat}
                handleValueChange={handleRangeItemsChange}
            />
            <RangeInput
                type="number"
                start_name="start_sum_without_vat"
                end_name="end_sum_without_vat"
                label="Shuma totale pa TVSH"
                start_placeholder="Nga"
                end_placeholder="Deri"
                start_value={start_sum_without_vat}
                end_value={end_sum_without_vat}
                handleValueChange={handleRangeItemsChange}
            />
            <div className="flex flex-col gap-2">
                <label htmlFor="region" className={labelStyle}>Regjioni:</label>
                <Select
                    isMulti
                    name="zip_codes"
                    options={cities.map(city => {
                        return {
                            value: city.zip_code,
                            label: city.name
                        }
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Të gjitha regjionet"
                    onChange={handleDropdownMultiSelectChange}
                    value={cities.filter(city => zip_codes?.includes(city.zip_code)).map(city => ({
                        value: city.zip_code,
                        label: city.name
                    }))}
                />
            </div>
        </div>
    )
}