import { useState } from "react";
import { tBodyItemStyle } from "../users/UserListItem";
import { cities } from "../notifications/AddNotification";

const removeHtmlTags = (html) => {
  return html?.replace(/<\/?[^>]+(>|$)/g, "");
};

export default function GameRulesListItem({ game }) {

  const [detailsOpen, setDetailsOpen] = useState(false)

  const handleRowClick = () => {
    setDetailsOpen(!detailsOpen)
    // navigate(`/games/${notification.id}/edit`)
  };

  return (
    <>
      <tr className="table_row" onClick={handleRowClick}>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {game.title}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {removeHtmlTags(game.description?.slice(0, 30))}
          {game?.description?.length > 30 && "..."}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {game?.tiers.length}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {game?.start_date && new Date(game?.start_date).toLocaleDateString("en-GB")}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {game?.end_date && new Date(game?.end_date).toLocaleDateString("en-GB")}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {game.zip_codes ? cities.find(city => game.zip_codes.split(",").includes(city.zip_code))?.name : <span className="italic text-gray-400 font-light">All regions</span>}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">
          {game.NAC_codes ? game.NAC_codes : <span className="italic text-gray-400 font-light">All codes</span>}
        </td>
      </tr>
      {detailsOpen && (
        <>
          <tr className="w-full !bg-gray-100">
            <td></td>
            <td style={tBodyItemStyle}>Tier Level</td>
            <td style={tBodyItemStyle}>Required Receipts</td>
            <td style={tBodyItemStyle}>Winning Amount</td>
            <td></td>
            <td></td>
          </tr>
          {game?.tiers?.map(tier => (
            <tr>
              <td></td>
              <td style={tBodyItemStyle}>{tier.tier_level}</td>
              <td style={tBodyItemStyle}>{tier.required_receipts}</td>
              <td style={tBodyItemStyle}>{tier.winning_amount}€</td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </>
      )}
    </>
  );
}
