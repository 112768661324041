import { cities } from "../notifications/AddNotification"
import { inputStyle, labelStyle, MultiSelectInput, RangeInput } from "./utils"
import Select from 'react-select';

export const UsersFilters = ({ multiSelectFilters, handleMultiSelectFiltersChange, multiSelectItems, handleMultiSelectItemsChange, rangeItems, handleRangeItemsChange, dropdownMultiSelectItems, handleDropdownMultiSelectChange, handleDeleteMultiSelectFilters }) => {

    const { personal_numbers, bank_account_numbers, bank_ids } = multiSelectItems
    const { start_num_receipts, end_num_receipts, start_sum_with_vat, end_sum_with_vat, start_sum_without_vat, end_sum_without_vat } = rangeItems
    const { zip_codes } = dropdownMultiSelectItems
    const { personal_numbers: selectedPersonalNumbers, bank_account_numbers: selectedBankAccountNumbers, selectedBankIds, selectedZipCodes } = multiSelectFilters

    console.log({ zip_codes })

    return (
        <div className="flex flex-col gap-4">
            <MultiSelectInput
                type="number"
                name="personal_numbers"
                label="Numri personal"
                placeholder="Kerko sipas numrit personal"
                value={personal_numbers}
                handleValueChange={handleMultiSelectItemsChange}
                handleSelectedValuesChange={handleMultiSelectFiltersChange}
                selectedValues={selectedPersonalNumbers}
                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
            />
            <MultiSelectInput
                type="number"
                name="bank_account_numbers"
                label="Numri i bankës"
                placeholder="Kerko sipas numrit të bankës"
                value={bank_account_numbers}
                handleValueChange={handleMultiSelectItemsChange}
                handleSelectedValuesChange={handleMultiSelectFiltersChange}
                selectedValues={selectedBankAccountNumbers}
                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
            />
            <div className="flex flex-col gap-2">
                <label htmlFor="region" className={labelStyle}>Regjioni:</label>
                <Select
                    isMulti
                    name="zip_codes"
                    options={cities.map(city => {
                        return {
                            value: city.zip_code,
                            label: city.name
                        }
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Të gjitha regjionet"
                    onChange={handleDropdownMultiSelectChange}
                    value={cities.filter(city => zip_codes?.includes(city.zip_code)).map(city => ({
                        value: city.zip_code,
                        label: city.name
                    }))}
                />
            </div>
            <RangeInput
                type="number"
                start_name="start_num_receipts"
                end_name="end_num_receipts"
                label="Numri i faturave"
                start_placeholder="Nga"
                end_placeholder="Deri"
                start_value={start_num_receipts}
                end_value={end_num_receipts}
                handleValueChange={handleRangeItemsChange}
            />
            <RangeInput
                type="number"
                start_name="start_sum_with_vat"
                end_name="end_sum_with_vat"
                label="Shuma totale me TVSH"
                start_placeholder="Nga"
                end_placeholder="Deri"
                start_value={start_sum_with_vat}
                end_value={end_sum_with_vat}
                handleValueChange={handleRangeItemsChange}
            />
            <RangeInput
                type="number"
                start_name="start_sum_without_vat"
                end_name="end_sum_without_vat"
                label="Shuma totale pa TVSH"
                start_placeholder="Nga"
                end_placeholder="Deri"
                start_value={start_sum_without_vat}
                end_value={end_sum_without_vat}
                handleValueChange={handleRangeItemsChange}
            />
        </div >
    )
}