import { useEffect, useState } from "react";
import WinnersModal from "./WinnersModal";
import { jsPDF } from "jspdf";
import DashboardHeader from "./DashboardHeader";
import DashboardFilters from "./DashboardFilters";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useCloseGameMutation, useGenerateWinnersMutation, useGetGameDetailsQuery, useLazyGetUsersWithFilledConditionsQuery } from "../store/services/prizeServices";
import { useDispatch } from "react-redux";
import { openModalWithTimeout } from "../store/alertSlice";
import DiceLoading from "./DiceLoader/DiceLoading";
import { BsBarChart } from "react-icons/bs";
import { VscGame } from "react-icons/vsc";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { TbFileEuro } from "react-icons/tb";
import { PiUsersThreeBold } from "react-icons/pi";
import { FaAward } from "react-icons/fa";
import Confetti from 'react-confetti';
import UsersModal from "./prizes/UsersModal";
import { useGetUsersWithFilledConditionsQuery } from "../store/services/prizeServices";
import { MdDateRange } from "react-icons/md";
import Loading from "./Loading.";
import { IoDiceSharp } from "react-icons/io5";
import { cities } from "./notifications/AddNotification";
import { labelStyle } from "./reports/utils";
import Select from 'react-select';

// const tiers = [
//     {
//         game_id: 1,
//         id: 1,
//         number_of_receipts: 30,
//         sum_of_receipts: 100,
//         eligible_players: 5000,
//         winners_prize: 100,
//     },
//     {
//         game_id: 1,
//         id: 2,
//         number_of_receipts: 50,
//         sum_of_receipts: 200,
//         eligible_players: 2500,
//         winners_prize: 200
//     },
//     {
//         game_id: 1,
//         id: 3,
//         number_of_receipts: 100,
//         sum_of_receipts: 1000,
//         eligible_players: 100,
//         winners_prize: 500
//     }
// ]

const users = [
    {
        name: 'John Doe',
        personal_number: 1231231231,
    },
    {
        name: 'Alice Smith',
        personal_number: 9879879879,
    },
    {
        name: 'Bob Johnson',
        personal_number: 5465645466,
    },
    {
        name: 'Charlie Brown',
        personal_number: 8877878788,
    },
    {
        name: 'David Williams',
        personal_number: 2342342344,
    },
    {
        name: 'Emma Davis',
        personal_number: 6767676776,
    },
    {
        name: 'Frank Miller',
        personal_number: 8877878788,
    },
    {
        name: 'Grace Wilson',
        personal_number: 2342342344,
    },
    {
        name: 'Henry Clark',
        personal_number: 1111111111,
    },
    {
        name: 'Ivy Martinez',
        personal_number: 2222222222,
    },
    {
        name: 'Jack Taylor',
        personal_number: 3333333333,
    },
    {
        name: 'Katie Anderson',
        personal_number: 4444444444,
    },
    {
        name: 'Leo Thomas',
        personal_number: 5555555555,
    },
    {
        name: 'Mia White',
        personal_number: 6666666666,
    },
    {
        name: 'Nathan Scott',
        personal_number: 7777777777,
    },
    {
        name: 'Olivia Harris',
        personal_number: 8888888888,
    },
    {
        name: 'Paul Moore',
        personal_number: 9999999999,
    },
    {
        name: 'Quinn Hall',
        personal_number: 1212121121,
    }
];

const Tab = ({ icon = null, title, value = null, handlePreviousClick = null, handleNextClick = null, handleClick = null }) => {
    return (
        <div className={`bg-white w-full items-center border border-l-4 border-l-[#283673] rounded-xl shadow-sm ${handleClick && 'cursor-pointer hover:bg-gray-50'}`} onClick={handleClick}>
            <div>
                <div className="flex items-center gap-8 p-8">
                    <div className="border rounded-lg p-2 text-secondary">{icon}</div>
                    <div className="flex flex-col gap-1 w-full">
                        <div className={`font-light text-gray-600 text-lg ${handleClick && 'hover:underline'}`}>{title}</div>
                        {handlePreviousClick ? (
                            <div className="flex gap-4 items-center">
                                <div className="cursor-pointer"><FaArrowLeft size={14} onClick={handlePreviousClick} /></div>
                                <span className="text-3xl font-semibold">{value}</span>
                                <div className="cursor-pointer"><FaArrowRight size={14} onClick={handleNextClick} /></div>
                            </div>
                        ) : (
                            <div className="text-3xl font-semibold">{value}</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const getRandomUsers = (array, num) => {
    const shuffled = [...array].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, num);
};

export const GenerateWinners = () => {

    const [randomUsers, setRandomUsers] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [length, setLength] = useState(null)
    const [games, setGames] = useState([])
    const [activeTierIndex, setActiveTierIndex] = useState(0)
    const [activeGameIndex, setActiveGameIndex] = useState(0)
    const [selectedGame, setSelectedGame] = useState({
    })
    const [selectedTier, setSelectedTier] = useState({
        game_id: 1,
        id: 1,
        required_receipts: 30,
        sum_of_receipts: 100,
        user_count: 0,
        winning_amount: 100,
    })

    const dispatch = useDispatch()

    const [winners, setWinners] = useState([])
    const { data, isLoading, isError } = useGetGameDetailsQuery();
    const [pizzaSpinOpen, setPizzaSpinOpen] = useState(false)
    const [singleWinner, setSingleWinner] = useState({})
    const [pastWinnersExcluded, setPastWinnersExcluded] = useState(true)

    const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);
    const [getPotentialWinners, { data: potentialWinners }] = useLazyGetUsersWithFilledConditionsQuery();

    const [generateWinners, { data: winnersData }] = useGenerateWinnersMutation()
    const [closeGame] = useCloseGameMutation();
    const [zipCodes, setZipCodes] = useState([])

    useEffect(() => {
        if (data) {
            setGames(data)
            setSelectedGame(data[0])
            setSelectedTier(data[0]?.tiers[0])
        }
    }, [data])

    useEffect(() => {
        if (selectedGame && selectedGame.tiers) {
            setSelectedTier(selectedGame.tiers[0])
        }
    }, [selectedGame])

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (selectedTier.potential_winners_count < 1) {
            dispatch(openModalWithTimeout({ text: 'Nuk ka fitues të kushtit për këtë nivel', type: 'error' }))
            return;
        }
        if (!length) {
            dispatch(openModalWithTimeout({ text: 'Zgjedh numrin e fituesve', type: 'error' }))
            return;
        }
        if (length > selectedTier.potential_winners_count) {
            dispatch(openModalWithTimeout({ text: 'Numri i fituesve nuk mund të jetë më i madh se numri i fituesve potencial', type: 'error' }))
            return;
        }
        const { data, error } = await generateWinners({ game_id: selectedGame.game_id, tier_level: selectedTier.tier_level, limit: parseInt(length), past_winners_excluded: pastWinnersExcluded })

        if (!Array.isArray(data)) {
            dispatch(openModalWithTimeout({ text: '' }))
        }

        setPizzaSpinOpen(true)
        const delay = 2000;
        const randomUsers = getRandomUsers(users, length) // to be deleted
        setWinners(data) // to be changed
        data?.forEach((item, index) => {
            setTimeout(() => {
                setPizzaSpinOpen(true);
                setSingleWinner({});

                setTimeout(() => {
                    setSingleWinner(item);
                    setPizzaSpinOpen(false);

                    // If it's the last item, finally set it to false
                    if (index === data?.length - 1) {
                        setTimeout(() => {
                            setPizzaSpinOpen(false);
                            setSingleWinner({});
                            setIsModalOpen(true)
                        }, delay);
                    }
                }, delay);
            }, index * delay * 2);
        });
        // setTimeout(() => {
        //     setPizzaSpinOpen(false)
        //     setIsModalOpen(true);
        // }, delay);
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const handleOpenUsersModal = async () => {
        const data = await getPotentialWinners({ game_id: selectedGame.game_id, tier_level: selectedTier.tier_level }).unwrap();
        if (data.length > 0) {
            setIsUsersModalOpen(true);
        }
    };

    const buttons = [
        { label: '', color: 'bg-white invisible', border: 'border border-[#D0D5DD]', shadow: true, onClick: null, linkTo: '/payments/approved' }
    ]

    const details = {
        title: 'Prizes',
        // count: prizes.length
    }

    const grid_item_class = "bg-white p-8 flex justify-center items-center text-2xl border shadow-lg"

    const handleNextTierClick = (e) => {
        e.preventDefault()
        const updatedIndex = activeTierIndex + 1
        if (updatedIndex > selectedGame.tiers.length - 1) {
            setActiveTierIndex(0)
            setSelectedTier(selectedGame.tiers[0])
        }
        else {
            setActiveTierIndex(updatedIndex)
            setSelectedTier(selectedGame.tiers[updatedIndex])
        }
    }

    const handlePreviousTierClick = () => {
        const updatedIndex = activeTierIndex - 1
        console.log({ updatedIndex })
        if (updatedIndex < 0) {
            setActiveTierIndex(selectedGame?.tiers?.length - 1)
            setSelectedTier(selectedGame?.tiers[selectedGame.tiers.length - 1])
        }
        else {
            setActiveTierIndex(updatedIndex)
            setSelectedTier(selectedGame.tiers[updatedIndex])
        }
    }

    const handleNextGameClick = () => {
        const updatedIndex = activeGameIndex + 1
        if (updatedIndex > games.length - 1) {
            setActiveGameIndex(0)
            setSelectedGame(games[0])
        }
        else {
            setActiveGameIndex(updatedIndex)
            setSelectedGame(games[updatedIndex])
        }
    }

    const handlePreviousGameClick = () => {
        const updatedIndex = activeGameIndex - 1
        if (updatedIndex < 0) {
            setActiveGameIndex(games.length - 1)
            setSelectedGame(games[games.length - 1])
        }
        else {
            setActiveGameIndex(updatedIndex)
            setSelectedGame(games[updatedIndex])
        }
    }

    const handleCloseGame = async () => {
        try {
            await closeGame({ game_id: selectedGame.game_id }).unwrap();
            dispatch(openModalWithTimeout({ text: 'Loja u mbyll me sukses!', type: 'success' }))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Diçka shkoi keq', type: 'error' }))
        }
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    if (!data) {
        return (
            <div className="w-full h-full flex items-center justify-center italic text-xl text-gray-400">Për momentin nuk ka asnjë lojë që mund të gjenerojë fitues </div>
        )
    }


    return (
        <div className="flex flex-col h-full overflow-y-scroll no-scrollbar">
            <DashboardHeader buttons={buttons} details={details} />
            <hr />
            <WinnersModal isOpen={isModalOpen} handleIsOpen={closeModal} winners={winners} />
            <UsersModal
                isOpen={isUsersModalOpen}
                handleClose={() => setIsUsersModalOpen(false)}
                users={potentialWinners || []}
            />
            <div className="w-full h-full">
                <div className="w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-8 sm:p-8 gap-2 sm:p-2">
                    <Tab title="Loja" value={selectedGame?.title} icon={<VscGame size={24} />} handlePreviousClick={handlePreviousGameClick} handleNextClick={handleNextGameClick} />
                    <Tab title="Periudha kohore e lojës" value={`${new Date(selectedGame?.start_date).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit" })} - ${new Date(selectedGame?.end_date).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit" })}`} icon={<MdDateRange size={24} />} />
                    <Tab title="Niveli" value={selectedTier?.tier_level} icon={<BsBarChart size={24} />} handlePreviousClick={handlePreviousTierClick} handleNextClick={handleNextTierClick} />
                    <Tab title="Numri i kuponave" value={selectedTier?.required_receipts} icon={<HiOutlineDocumentCheck size={24} />} />
                    {/* <Tab title="Shuma e kuponave" value="100€" icon={<TbFileEuro size={24} />} /> */}
                    <Tab title="Fitues potencial" value={selectedTier?.potential_winners_count} icon={<PiUsersThreeBold size={24} />} handleClick={handleOpenUsersModal} />
                    <Tab title="Shpërblimi" value={`${selectedTier?.winning_amount}€`} icon={<FaAward size={24} />} />
                </div>
                <div className="w-full flex justify-center">
                    <div className="w-full xl:w-1/2 lg:w-2/3 flex flex-col gap-8 items-center p-4">
                        {winners.length <= 0 && (
                            <div className="w-full flex sm:flex-row flex-col items-center gap-4">
                                <div className="w-full flex flex-col items-center gap-4">
                                    <label htmlFor="zip_codes">Regjioni</label>
                                    <Select
                                        isMulti
                                        name="zip_codes"
                                        options={cities.map(city => {
                                            return {
                                                value: city.zip_code,
                                                label: city.name
                                            }
                                        })}
                                        className="basic-multi-select w-full"
                                        classNamePrefix="select"
                                        placeholder="Të gjitha regjionet"
                                        onChange={(selectedOptions) => {
                                            const values = selectedOptions.map(option => option.value)
                                            setZipCodes(values)
                                        }}
                                        value={cities.filter(city => zipCodes?.includes(city.zip_code)).map(city => ({
                                            value: city.zip_code,
                                            label: city.name
                                        }))}
                                    />
                                </div>
                                <div className="w-full flex flex-col items-center gap-4">
                                    <label>Zgjedh numrin e fituesve</label>
                                    <input type="number" min={0} value={length} onChange={(e) => setLength(e.target.value)} className="border text-xl text-center px-4 p-[10px] outline-none w-full rounded-lg" placeholder="0" />
                                </div>
                            </div>
                        )}
                        {winners.length <= 0 && (
                            <div className="w-full justify-between">
                                <div className="flex items-center gap-3">
                                    <input name="ineligibleUsers" id="ineligibleUsers" type="checkbox" className="form-checkbox w-5 h-5 rounded-full" checked={pastWinnersExcluded} onChange={(e) => setPastWinnersExcluded(e.target.checked)} />
                                    <label htmlFor="ineligibleUsers" className="leading-3">Përjashto fituesit e mëparshëm</label>
                                </div>
                            </div>
                        )}
                        <button onClick={handleButtonClick} disabled={winners.length > 0} className="p-4 w-full rounded-lg bg-primary text-xl font-bold text-white disabled:bg-gray-100 disabled:text-gray-600 disabled:font-light">
                            {winners.length > 0 ? (
                                <span>Lista e fituesve u gjenerua me sukses!</span>
                            ) : (
                                <div className="flex items-center gap-4 justify-center">
                                    <span>Gjenero fituesit</span>
                                    <span><IoDiceSharp size={24} /></span>
                                </div>
                            )}
                        </button>
                        {winners.length > 0 && (
                            <div className="text-primary font-semibold flex justify-center hover:underline cursor-pointer" onClick={() => setIsModalOpen(true)}>Hap listën e fituesve</div>
                        )}
                        <div className="w-full flex justify-end">
                            <div className="p-1 w-max text-center border rounded-xl px-4 hover:bg-red-600 hover:text-white border-red-600 text-red-600 cursor-pointer" onClick={handleCloseGame}>Mbyll lojën</div>
                        </div>
                    </div>
                </div>
                {pizzaSpinOpen || (Object.values(singleWinner).length > 0) ? (
                    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full p-2 bg-transparent backdrop-blur-md">
                        {pizzaSpinOpen && (
                            <div className="">
                                <DiceLoading />
                            </div>
                        )}
                        <div className={`${Object.values(singleWinner).length > 0 ? 'w-[500px]' : 'w-[0px] overflow-hidden opacity-0'} transition-all text-center text-2xl p-4 bg-secondary text-white flex items-center justify-center`}>
                            <span className={`${Object.values(singleWinner).length > 0 ? 'max-w-[500px]' : 'max-w-[0px] overflow-hidden opacity-0'}`}>
                                <div className="flex flex-col gap-1 items-center">
                                    <span className="font-extralight">Numri fitues: </span>
                                    <span className="font-bold tracking-wider text-4xl drop-shadow-[1px_1px_1px_rgba(0,0,0,0.5)]">
                                        {singleWinner.personal_number}
                                        {/* {singleWinner.personal_number.toString().split("").map(char => (
                                        <div className="w-8 h-8 flex items-center justify-center">{char}</div>
                                    ))} */}
                                    </span>
                                </div>
                            </span>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};