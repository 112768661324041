import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import PuffLoader from "react-spinners/PuffLoader";
import { TiPrinter } from "react-icons/ti";
import { useDispatch } from 'react-redux';
import { openModalWithTimeout } from '../store/alertSlice';
import { useNavigate } from 'react-router';
import { BiUser } from "react-icons/bi";
import { LiaUser } from "react-icons/lia";
import { exportDataToExcel } from './reports/utils';
import { CircularProgress } from '@mui/material';

const customStyles = {
    content: {
        position: 'static',
        border: 'none',
        background: 'none',
    },
    overlay: {
        background: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)', // Add this line for blur effect
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function Loading() {

    useEffect(() => {
        const mainElement = document.getElementById('main');
        if (mainElement) {
            Modal.setAppElement(mainElement);
        }
    }, []);

    return (
        <Modal
            isOpen={true}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <CircularProgress />
        </Modal>
    )
}
