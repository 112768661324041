import { cities } from "../notifications/AddNotification"
import { labelStyle, MultiSelectInput } from "./utils"
import Select from 'react-select';
import "./MultiSelect.css";
import { useGetGamesQuery } from "../../store/services/gameServices";
import { useEffect, useState } from "react";

export const WinnersFilters = ({ multiSelectFilters, handleMultiSelectFiltersChange, multiSelectItems, handleMultiSelectItemsChange, rangeItems, handleRangeItemsChange, dropdownMultiSelectItems, handleDropdownMultiSelectChange, handleDeleteMultiSelectFilters, dropdownSelectItems, handleDropdownSelectChange }) => {

    const { personal_numbers, bank_account_numbers } = multiSelectItems
    const { game_id, tier_level } = dropdownSelectItems
    const { start_num_receipts, end_num_receipts, start_sum_with_vat, end_sum_with_vat, start_sum_without_vat, end_sum_without_vat } = rangeItems
    const { zip_codes } = dropdownMultiSelectItems
    const { personal_numbers: selectedPersonalNumbers, bank_account_numbers: selectedBankAccountNumbers } = multiSelectFilters

    const [selectedGame, setSelectedGame] = useState({})
    const [tierOptions, setTierOptions] = useState([])

    const { data: gamesData } = useGetGamesQuery()

    useEffect(() => {
        if (gamesData && gamesData.data) {
            if (game_id) {
                setSelectedGame(gamesData.data.find(game => game.game_id == game_id))
            }
        }
    }, [gamesData, game_id])

    useEffect(() => {
        if (selectedGame) {
            setTierOptions(selectedGame.tiers)
        }
    }, [selectedGame])

    useEffect(() => {
        console.log({ tierOptions })
    }, [tierOptions])

    return (
        <div className="flex flex-col gap-4">
            <MultiSelectInput
                type="number"
                name="personal_numbers"
                label="Numri personal"
                placeholder="Kerko sipas numrit personal"
                value={personal_numbers}
                handleValueChange={handleMultiSelectItemsChange}
                handleSelectedValuesChange={handleMultiSelectFiltersChange}
                selectedValues={selectedPersonalNumbers}
                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
            />
            <MultiSelectInput
                type="number"
                name="bank_account_numbers"
                label="Numri i bankës"
                placeholder="Kerko sipas numrit të bankës"
                value={bank_account_numbers}
                handleValueChange={handleMultiSelectItemsChange}
                handleSelectedValuesChange={handleMultiSelectFiltersChange}
                selectedValues={selectedBankAccountNumbers}
                handleDeleteMultiSelectFilters={handleDeleteMultiSelectFilters}
            />
            <div className="flex flex-col gap-2">
                <label htmlFor="region" className={labelStyle}>Regjioni:</label>
                <Select
                    isMulti
                    name="zip_codes"
                    options={cities.map(city => {
                        return {
                            value: city.zip_code,
                            label: city.name
                        }
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Të gjitha regjionet"
                    onChange={handleDropdownMultiSelectChange}
                    value={cities.filter(city => zip_codes?.includes(city.zip_code)).map(city => ({
                        value: city.zip_code,
                        label: city.name
                    }))}
                />
            </div>
            <div className="flex flex-col gap-2">
                <label htmlFor="region" className={labelStyle}>Loja:</label>
                <Select
                    name="game_id"
                    options={gamesData?.data?.map(game => {
                        return {
                            value: game.game_id,
                            label: game.title
                        }
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Të gjitha lojerat"
                    onChange={handleDropdownSelectChange}
                    value={gamesData?.data?.find(game => game.game_id == game_id) ?
                        { value: game_id, label: gamesData?.data.find(game => game.game_id == game_id)?.title }
                        : undefined}
                />
            </div>
            <div className="flex flex-col gap-2">
                <label htmlFor="region" className={labelStyle}>Niveli:</label>
                <Select
                    name="tier_level"
                    options={tierOptions?.map((tier, index) => ({
                        value: tier.tier_level,
                        label: `Niveli ${tier.tier_level}`
                    }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Të gjitha nivelet"
                    onChange={handleDropdownSelectChange}
                    value={(() => {
                        const selectedIndex = tierOptions?.findIndex(tier => tier.tier_level == tier_level);
                        if (selectedIndex !== -1 && selectedIndex !== undefined) {
                            return {
                                value: tier_level,
                                label: `Niveli ${tier_level}`
                            };
                        }
                        return undefined;
                    })()}
                />
            </div>
        </div>
    )
}