import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi";
import DashboardFilters from "../DashboardFilters";
import DashboardHeader from "../DashboardHeader";
import DashboardTable from "../DashboardTable";
import { useEffect, useRef, useState } from "react";
import { RiDownloadLine } from "react-icons/ri";
import PrizesList from "./PrizesList";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import { useGetWinnersQuery } from "../../store/services/prizeServices";

export default function PrizesMain() {
  const { data } = useGetWinnersQuery();
  const [filteredPrizes, setFilteredPrizes] = useState(data?.data || []);
  const [filterSelected, setFilterSelected] = useState("");
  const [showFilters, setShowFilters] = useState(true);
  const [downloadOptionsExtended, setDownloadOptionsExtended] = useState(false);
  const downloadOptionsRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        downloadOptionsRef.current &&
        !downloadOptionsRef.current.contains(event.target)
      ) {
        setDownloadOptionsExtended(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDownloadOptionsExtended]);

  const handleFilterSelect = (e) => {
    if (e.target.id === "Te gjitha") {
      setFilterSelected("");
      return;
    }
    setFilterSelected(e.target.innerText);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  // useEffect(() => {
  //   if (!filterSelected.length) {
  //     setFilteredPrizes(data);
  //     return;
  //   }
  //   setFilteredPrizes(
  //     data.filter((prize) => prize.payment_type === filterSelected)
  //   );
  // }, [filterSelected]);

  const buttons = [
    {
      label: "Gjenero fituesit",
      color: "bg-white",
      icon: <RiDownloadLine />,
      border: "border border-gray-300",
      onClick: () => {
        setDownloadOptionsExtended(!downloadOptionsExtended);
      },
    },
  ];

  const headerButtons = [
    {
      label: "Gjenero raportin",
      color: "bg-white",
      icon: <RiDownloadLine />,
      border: "border border-gray-300 invisible",
      linkTo: "/reports",
    },
  ];

  const details = {
    title: "Winners",
    count: data?.length,
  };

  const tableData = {
    headLabels: [
      "ID",
      "Name & Surname",
      "Email",
      "Game",
      "Tier Level",
      "Receipt Count",
      "Date",
    ],
    list: <PrizesList prizes={data?.data || []} />,
    data: data,
    actions: false,
  };

  const filters = {
    categories: [],
    selectedFilter: filterSelected,
    handleFilterSelect: handleFilterSelect,
    showFilters: showFilters,
    handleShowFilters: handleShowFilters,
  };

  const exportDataToExcel = (data) => {
    if (data === undefined || data === "undefined") {
      alert("Nuk ka te dhena per te gjeneruar");
      return;
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const columnKeys = Object.keys(data[0] || {});

    const colWidths = columnKeys.map((key) => {
      const maxLength = Math.max(
        key.length,
        ...data.map((row) => (row[key] ? row[key].toString().length : 0))
      );
      return { wch: maxLength + 2 };
    });

    ws["!cols"] = colWidths;

    columnKeys.forEach((key, colIndex) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex });
      if (ws[cellAddress]) {
        ws[cellAddress].s = {
          font: { bold: true },
        };
      }
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Te dhenat");

    XLSX.writeFile(wb, "Fituesit_GIZ_Dashboard.xlsx");
  };
  const exportToPdf = (data) => {
    const pdf = new jsPDF();
    let yPosition = 10;

    data.forEach((item, index) => {
      pdf.setFontSize(14);
      pdf.text(`Winner ${index + 1}: ${item.winner}`, 10, yPosition);
      yPosition += 10;

      Object.entries(item).forEach(([key, value]) => {
        pdf.setFontSize(12);
        pdf.text(`${key}: ${value}`, 10, yPosition);
        yPosition += 10;
      });

      yPosition += 5;
    });

    pdf.save("exported_winners.pdf");
  };

  return (
    <>
      <DashboardHeader buttons={headerButtons} details={details} />
      <hr />
      <div className="flex">
        <DashboardFilters filters={filters} />
        <div className="w-full ">
          <div className="p-4 flex justify-end">
            {buttons.map((button) => (
              <div className="relative">
                <div
                  ref={downloadOptionsRef}
                  className="p-2 flex items-center gap-2 text-sm font-bold px-4 w-max border hover:border-gray-300 rounded-xl cursor-pointer"
                  onClick={() => setDownloadOptionsExtended((prev) => !prev)}
                >
                  <div>Gjenero fituesit</div>
                  <RiDownloadLine />
                </div>

                {downloadOptionsExtended && (
                  <div className="absolute left-0 w-full text-sm rounded-xl border flex flex-col bg-white z-10 shadow-md transition-all ">
                    <div
                      className="h-1/2 flex items-center justify-center gap-2 p-2 rounded-xl hover:bg-gray-100 cursor-pointer"
                      onClick={() => exportDataToExcel(data)}
                    >
                      <RiFileExcel2Line size={16} color="green" />
                      <div>Excel file (.xlsx)</div>
                    </div>
                    <div
                      className="h-1/2 flex items-center justify-center gap-2 p-2 rounded-xl hover:bg-gray-100 cursor-pointer"
                      onClick={() => exportToPdf(data)}
                    >
                      <FaRegFilePdf size={16} color="red" />
                      <div>PDF file (.pdf)</div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full">
        <DashboardTable tableData={tableData} />
      </div>
      <div className="p-4 flex justify-between items-center w-full text-sm font-semibold">
        <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
          <PiArrowLeftBold style={{ fontSize: "18px" }} />
          <div className="leading-3">Previous</div>
        </div>
        <div>Pages</div>
        <div className="px-3 p-2 border border-gray-200 rounded-xl flex gap-2 items-center cursor-pointer">
          <div className="leading-3">Next</div>
          <PiArrowRightBold style={{ fontSize: "18px" }} />
        </div>
      </div>
    </>
  );
}
