import { useNavigate } from "react-router-dom";
import { tBodyItemStyle } from "../users/UserListItem";
import { useDispatch } from "react-redux";
import { modalActions } from "../../store/modalSlice";

import { notificationViewActions } from "../../store/services/notificationViewSlice";

const removeHtmlTags = (html) => {
  return html?.replace(/<\/?[^>]+(>|$)/g, "");
};

export default function NotificationsListItem({ notification }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlenotificationDelete = (e) => {
    e.stopPropagation();
    dispatch(
      modalActions.openModal({
        details: {
          id: notification.id,
          name: notification.title,
          category: "notification",
        },
      })
    );
  };

  const handleRowClick = () => {
    // dispatch(notificationViewActions.openModal(notification));
  };

  return (
    <>
      <tr className="table_row" onClick={handleRowClick}>
        <td style={tBodyItemStyle} className="text-black font-medium">
          {notification.id}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium">
          {notification.title}
        </td>
        <td style={tBodyItemStyle} className="text-black font-medium">
          {removeHtmlTags(notification.body?.slice(0, 30))}
          {notification.body.length > 30 && "..."}
        </td>
        <td style={tBodyItemStyle} className="">
          {notification.created_at}
        </td>
        <td style={{ ...tBodyItemStyle, padding: "0px 20px" }} className="">
          <div className="w-[100px] h-[50px]">
            {/* <img src={`${process.env.REACT_APP_BACKEND_HOST}/${notification.image_path}`} alt="notification_img" className="w-full h-full object-cover rounded-xl" /> */}
            {notification.image_path && (
              <img
                src={`${process.env.REACT_APP_BACKEND_HOST}/api/${notification.image_path}`}
                alt="notification_img"
                className="w-full h-full object-cover rounded-xl"
              />
            )}
            {!notification.image_path && <></>}
          </div>
        </td>
      </tr>
    </>
  );
}
