import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const complaintsApi = createApi({
    reducerPath: 'complaintsApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
    }),
    endpoints: (build) => ({
        getComplaints: build.query({
            query(params) {
                return {
                    url: `/admin/complaints`,
                    method: 'GET',
                    params: params
                }
            },
            providesTags: ['Complaints']
        }),
        replyToComplaint: build.mutation({
            query(data) {
                console.log({ data })
                return {
                    url: `/notifications/reply-complaint`,
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    method: 'POST',
                    data: data,
                    formData: true
                }
            },
            invalidatesTags: ['Complaints']
        }),
        // getFilteredReceipts: build.query({
        //     query({ params }) {
        //         console.log({ params })
        //         const queryParams = new URLSearchParams(params).toString()
        //         return {
        //             url: `/receipts/filter?${queryParams}`,
        //             method: 'GET'
        //         }
        //     }
        // })
    }),
});

export const {
    useGetComplaintsQuery,
    useReplyToComplaintMutation
} = complaintsApi;