import { createSlice } from "@reduxjs/toolkit";

const initialNonFiscalReceiptViewState = { receipt: {}, isOpen: false }

const nonFiscalReceiptViewSlice = createSlice({
    name: 'nonfiscal_receipt_view',
    initialState: initialNonFiscalReceiptViewState,
    reducers: {
        openModal(state, action) {
            state.receipt = action.payload
            state.isOpen = true
        },
        closeModal(state, action) {
            state.receipt = {}
            state.isOpen = false
        }
    }
})

export const nonFiscalReceiptViewActions = nonFiscalReceiptViewSlice.actions;
export default nonFiscalReceiptViewSlice