import { GoDotFill } from "react-icons/go"
import { getStatusClasses } from "../ReceiptsListItem"
import { LuTrash2 } from "react-icons/lu"
import { tBodyItemStyle } from "../../users/UserListItem"
import { modalActions } from "../../../store/modalSlice";
import { useDispatch } from "react-redux";
import { IoInformation } from "react-icons/io5";
import { nonFiscalReceiptViewActions } from "../../../store/nonFiscalReceiptViewSlice";

export default function NonFiscalReceiptsListItem({ receipt }) {

    const dispatch = useDispatch()

    function capitalizeFirstLetter(string) {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function convertToLocaleDateString(date) {
        return new Date(date).toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit'
        });
    }

    const handleDeleteApprovedReceipt = () => {
        console.log('armir')
        dispatch(modalActions.openModal(
            {
                details:
                {
                    id: receipt.receipt_id,
                    name: `${receipt.business_name} - ${receipt.name}`,
                    category: 'receipt'
                }
            }))
    }

    // const mainImage = receipt.images.find(image => image.is_main) || null

    return (
        <>
            <tr className={`table_row`} onClick={() => dispatch(nonFiscalReceiptViewActions.openModal(receipt))}>
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">{receipt.receipt_id}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">{convertToLocaleDateString(receipt.created_at)}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[10%]">
                    {receipt.NUI}
                </td>
                <td style={{ ...tBodyItemStyle, padding: '0px 20px', }} className="text-black font-medium w-[15%]">
                    {receipt.name}
                    {/* <div className="rounded-xl w-[100px] h-[50px]">
                        {mainImage ? (
                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/${mainImage.path}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                        ) : (
                            receipt.images[0]?.path ? (
                                <img src={`${process.env.REACT_APP_BACKEND_HOST}/${receipt.images[0].path}`} alt="img" className="rounded-xl object-cover h-full w-full" />
                            ) : (
                                <div className="border border-gray-300 rounded-xl object-cover h-full w-full flex items-center justify-center text-xs text-gray-400">
                                    <CiImageOff size={24} />
                                </div>
                            )
                        )}
                    </div> */}
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[15%] overflow-hidden">
                    {receipt.business_name}
                </td>
                <td style={tBodyItemStyle} className="w-[10%]">{receipt.vat_amount}€</td>
                <td style={tBodyItemStyle} className="w-[10%]">{receipt.total_amount}€</td>
                <td style={tBodyItemStyle} className="w-[5%]">
                    <span
                        className={`flex justify-center gap-1 items-center px-2 rounded-full p-[2px] text-xs font-semibold ${getStatusClasses(receipt.status)}`}
                        style={{ width: '100px' }}
                    >
                        <GoDotFill /> <span>{capitalizeFirstLetter(receipt.status)}</span>
                    </span>
                </td>
                <td style={tBodyItemStyle} className="w-[15%]">
                    <div className="flex justify-center gap-4 text-black">
                        <div className="rounded-full w-8 h-8 flex justify-center items-center text-center bg-primary relative cursor-pointer actions info-action" title="Get info">
                            <IoInformation style={{ fontSize: '20px', textAlign: 'center' }} />
                        </div>
                    </div>
                </td>
            </tr>
        </>
    )

}