import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modalSlice';
import { useDeleteUserMutation } from '../store/services/userServices';
import { selectedReceiptsActions } from '../store/selectedReceiptsSlice';
import { openModalWithTimeout } from '../store/alertSlice';
import { useCreatePropertyTypeMutation, useGetPropertyTypesQuery } from '../store/services/propertyTypeServices';
import { createItemModalActions } from '../store/createItemModalSlice';
import { useCreateSubPropertyTypeMutation } from '../store/services/subPropertyServices';
import { useCreateHeatingTypeMutation } from '../store/services/heatingTypeServices';
import { useCreateFurnishingTypeMutation } from '../store/services/furnishingTypeServices';
import { useCreateCharacteristicMutation } from '../store/services/characteristicsServices';
import { receiptViewActions } from '../store/receiptViewSlice';
import { labelStyle } from './users/EditUser';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useGetSubmittedReceiptByIdQuery, useVerifyReceiptMutation } from '../store/services/receiptsServices';
import { CircularProgress } from '@mui/material';
import { MdDone } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '20px',
        padding: '30px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)'
    }
};

const inputStyle = 'block rounded-md border border-gray-300 w-full p-3 mt-1 disabled:bg-white'

Modal.setAppElement(document.getElementById('root'))

export default function ReceiptView() {

    const [verifyReceipt] = useVerifyReceiptMutation()

    const dispatch = useDispatch()
    const receipt = useSelector(state => state.receipt_view)
    const isOpen = receipt.isOpen
    const [approveConfirm, setApproveConfirm] = useState(false)
    const [rejectConfirm, setRejectConfirm] = useState(false)
    const [images, setImages] = useState([])
    const [imageUploaded, setImageUploaded] = useState(false)

    const { data, isLoading } = useGetSubmittedReceiptByIdQuery({ id: receipt.receipt.receipt_id })

    console.log({ data })

    useEffect(() => {
        if (data) {
            if (data.image_path) {
                if (Array.isArray(data.image_path)) {
                    setImages(data.image_path)
                }
                else {
                    setImages(JSON.parse(data.image_path))
                }
            }
        }
    }, [data])

    function closeModal() {
        dispatch(receiptViewActions.closeModal())
        setApproveConfirm(false)
        setRejectConfirm(false)
    }

    const handleApprove = async () => {
        const data = { value: 'approve' }
        try {
            const response = await verifyReceipt({ id: receipt.receipt.receipt_id, data: data }).unwrap()
            console.log("Receipt status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Receipt approved successfully', type: 'success' }, 2000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change receipt status', type: 'error' }, 2000))
        }
        setApproveConfirm(false)
        dispatch(receiptViewActions.closeModal())
    }

    const handleReject = async () => {
        const data = { value: 'reject' }
        try {
            const response = await verifyReceipt({ id: receipt.receipt.receipt_id, data: data }).unwrap()
            console.log("Receipt status changed:", response)
            dispatch(openModalWithTimeout({ text: 'Receipt rejected successfully', type: 'success' }, 2000))
        }
        catch {
            dispatch(openModalWithTimeout({ text: 'Failed to change receipt status', type: 'error' }, 2000))
        }
        setApproveConfirm(false)
        dispatch(receiptViewActions.closeModal())
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="relative bg-white px-4 w-[800px] h-[600px] overflow-y-scroll scrollbar-thin scrollbar-track scrollbar-thumb">
                    {isLoading && (
                        <div className='absolute flex items-center justify-center top-0 left-0 w-full h-full bg-white z-20'>
                            <CircularProgress color='black' size={40} />
                        </div>
                    )}
                    <div className='flex gap-8 h-full'>
                        <div className='w-1/2 h-full no-scrollbar flex justify-center items-center'>
                            <div className='w-full h-full min-h-full overflow-y-scroll scrollbar-thin scrollbar-track scrollbar-thumb'>
                                {images.map(image => (
                                    <TransformWrapper
                                        initialScale={1}
                                        initialPositionX={0}
                                        initialPositionY={0}
                                        style={{ height: '100%' }}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <>
                                                <TransformComponent>
                                                    <div className='h-full w-full p-1 border border-gray-300'>
                                                        {!imageUploaded && <div className='flex items-center justify-center w-full h-full'><CircularProgress size={32} color='black' /></div>}
                                                        <div className='flex h-full w-full justify-center items-center '>
                                                            <img src={`${process.env.REACT_APP_BACKEND_HOST}/api/${image}`} alt="img" className="w-full h-full object-cover" onLoad={() => setImageUploaded(true)} />
                                                        </div>
                                                    </div>
                                                </TransformComponent>
                                            </>
                                        )}
                                    </TransformWrapper>
                                ))}
                            </div>
                        </div>
                        <div className='h-full flex flex-col gap-4 justify-between w-1/2 text-sm'>
                            <div>
                                <label className={labelStyle} htmlFor="vat_number">Numri fiskal</label>
                                <input type="tel" id="vat_number" name="vat_number" value={data?.NUI} className={inputStyle} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="vat_number">Numri serik</label>
                                <input type="tel" id="vat_number" name="vat_number" value={data?.fiscal_device_serial_number} className={inputStyle} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="company">Emri i kompanise</label>
                                <input type="text" id="company" name="company" value={data?.business_name} className={inputStyle} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="address">Adresa</label>
                                <input type="text" id="address" name="address" value={data?.address} className={inputStyle} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="total_without_vat">Shuma pa TVSH</label>
                                <input type="text" id="total_without_vat" name="total_without_vat" value={data?.total_without_vat} className={inputStyle} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="vat_amount">TVSH</label>
                                <input type="tel" id="vat_amount" name="vat_amount" value={data?.vat_amount} className={inputStyle} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="total_amount">Shuma totale</label>
                                <input type="tel" id="total_amount" name="total_amount" value={data?.total_with_vat} className={inputStyle} disabled />
                            </div>
                            <div>
                                <label className={labelStyle} htmlFor="release_date">Data e leshimit</label>
                                <input type="text" id="release_date" name="release_date" value={data?.release_date} className={inputStyle} disabled />
                            </div>
                            <div className='flex gap-8 sticky py-4 bottom-0 bg-white'>
                                <div className='flex items-center justify-center gap-2 w-full p-1 bg-white border border-red-600 text-red-600 text-center rounded-md cursor-pointer hover:bg-red-600 hover:text-white' onClick={handleReject}>
                                    <span><IoMdClose size={16} /></span>
                                    <span>Reject</span>
                                </div>
                                <div className='flex items-center justify-center gap-2 w-full p-1 bg-white border border-green-700 text-green-700 text-center rounded-md cursor-pointer hover:bg-green-700 hover:text-white' onClick={handleApprove}>
                                    <span>Approve</span>
                                    <span><MdDone size={16} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>)
}