import ApprovedReceiptsItem from "./ApprovedReceiptsItem"

export default function ApprovedReceiptsList({ receipts }) {
    return (
        <>
            {receipts.slice(0, 10).map(receipt => (
                <ApprovedReceiptsItem receipt={receipt} />
            ))}
        </>
    )
}