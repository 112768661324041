import { useState } from "react"

const MONTHS = [{ name: '1 muaj', value: 1 }, { name: '3 muaj', value: 3 }, { name: '6 muaj', value: 6 }, { name: '12 muaj', value: 12 }]

export const Test2Component = () => {

    const [prizeTypes, setPrizeTypes] = useState(1)
    const [months, setMonths] = useState(1)

    const now = new Date();
    const nextMonth = String(now.getMonth() + 2).padStart(2, '0')
    const nextExpiringMonth = String(parseInt(nextMonth) + parseInt(months)).padStart(2, '0');

    return (
        <div className="p-4 flex flex-col gap-4">
            <div>KRIJO NJE SISTEM TE RI TE LOJES</div>
            <div className="flex items-center gap-4">
                <div className="w-52">Periudha kohore</div>
                <div>
                    <select className="border p-1 w-24" value={months} onChange={(e) => setMonths(e.target.value)}>
                        {MONTHS.map(month => (
                            <option value={month.value}>{month.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div>Prej muajit {nextMonth} deri ne muajin {nextExpiringMonth}</div>
            <div>
                <div className="flex items-center gap-4">
                    <div className="w-52">Fazat e cmimeve</div>
                    <div>
                        <input className="border p-1 w-24" type="number" min={1} value={prizeTypes} onChange={(e) => setPrizeTypes(e.target.value)} />
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col gap-8">
                    {Array.from({ length: prizeTypes }, (_, index) => (
                        <div className="flex flex-col gap-2">
                            <div className="font-bold">Faza {index + 1}</div>
                            <div className="flex items-center gap-4">
                                <div className="w-52">Numri i kuponave</div>
                                <div>
                                    <input className="border p-1 w-24" type="number" />
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="w-52">Shuma totale e kuponave</div>
                                <div>
                                    <input className="border p-1 w-24" type="number" />
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="w-52">Numri i fituesve</div>
                                <div>
                                    <input className="border p-1 w-24" type="number" />
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="w-52">Shperblimi</div>
                                <div className="relative">
                                    <input className="border p-1 w-24" type="number" />
                                    <div className="absolute right-0 top-0 p-1 px-2">€</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}