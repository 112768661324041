import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modalSlice';
import { useDeleteBlogMutation } from '../store/services/blogServices';
import { useDeleteAccessoryMutation } from '../store/services/accessoryServices';
import { useDeleteUserMutation } from '../store/services/userServices';
import { useDeleteApprovedReceiptMutation, useSoftDeleteByIdMutation } from '../store/services/receiptsServices';
import { alertActions } from '../store/alertSlice';
import { selectedReceiptsActions } from '../store/selectedReceiptsSlice';
import { openModalWithTimeout } from '../store/alertSlice';
import { useDeletePropertyTypeMutation } from '../store/services/propertyTypeServices';
import { useDeleteSubPropertyTypeMutation } from '../store/services/subPropertyServices';
import { useDeleteHeatingTypeMutation } from '../store/services/heatingTypeServices';
import { useDeleteFurnishingTypeMutation } from '../store/services/furnishingTypeServices';
import { useDeleteCharacteristicMutation } from '../store/services/characteristicsServices';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '30px'
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)'
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function ReactModal() {

    const details = useSelector(state => state.modal.details)
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.modal.isOpen)
    const ids = useSelector(state => state.selectedReceipts.ids)
    const selectedReceipts = useSelector(state => state.selectedReceipts)

    const [deleteBlog] = useDeleteBlogMutation()
    const [deleteAccessory] = useDeleteAccessoryMutation()
    const [deleteUser] = useDeleteUserMutation()
    const [deletePropertyType] = useDeletePropertyTypeMutation()
    const [deleteSubPropertyType] = useDeleteSubPropertyTypeMutation()
    const [deleteHeatingType] = useDeleteHeatingTypeMutation()
    const [deleteFurnishingType] = useDeleteFurnishingTypeMutation()
    const [deleteCharacteristic] = useDeleteCharacteristicMutation()
    const [deleteApprovedReceipt] = useDeleteApprovedReceiptMutation()

    const [isSelectedReceiptsListOpen, setIsSelectedReceiptsListOpen] = useState(false)

    function closeModal() {
        dispatch(modalActions.closeModal())
        setIsSelectedReceiptsListOpen(false)
    }

    const handleCancel = () => {
        dispatch(modalActions.closeModal())
        setIsSelectedReceiptsListOpen(false)
    }

    const handleDelete = async () => {
        try {
            if (details.category === 'blog') {
                const response = await deleteBlog(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'receipt') {
                const response = await deleteApprovedReceipt(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'tip te prones') {
                const response = await deletePropertyType(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'nentip te prones') {
                const response = await deleteSubPropertyType(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'sistem te ngrohjes') {
                const response = await deleteHeatingType(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'mobilim') {
                const response = await deleteFurnishingType(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))

            }
            else if (details.category === 'karakteristike') {
                const response = await deleteCharacteristic(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'user') {
                const response = await deleteUser(details.id).unwrap()
                dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
            }
            else if (details.category === 'receipts') {
                // const response = await deleteReceipts(ids).unwrap()
                // dispatch(openModalWithTimeout({ text: response.message, type: 'success' }))
                // dispatch(selectedReceiptsActions.clearSelectedReceipts())
            }
            dispatch(modalActions.closeModal())
            setIsSelectedReceiptsListOpen(false)
            // openModalWithTimeout({ text: 'Operation completed successfully', type: 'success' });
        } catch (error) {
            dispatch(modalActions.closeModal())
            // openModalWithTimeout({ text: error.data.message, type: 'error' })
            console.log(error)
        }
    }

    if (details.category === 'receipts') {
        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="bg-white p-6 px-8 w-[650px]">
                        <h1 className="text-[25px] text-[#667085] leading-8">A jeni te sigurt qe deshironi te fshini keto {details.category}?</h1>
                        <h3 className="text-sm text-gray-400 mt-4">A jeni te sigurt qe deshironi te fshini keto <span className="text-black font-semibold cursor-pointer hover:underline    " onClick={() => setIsSelectedReceiptsListOpen(!isSelectedReceiptsListOpen)}>{details.name}?</span></h3>
                        <div className={`${isSelectedReceiptsListOpen ? 'max-h-48 mt-4' : 'max-h-0'} transition-all text-sm overflow-y-scroll scrollbar-thin scrollbar-track scrollbar-thumb`}>
                            <table className='w-full'>
                                <thead>
                                    <tr className='bg-gray-100 sticky top-0'>
                                        <td className='p-1'>#</td>
                                        <td>ID</td>
                                        <td>Name</td>
                                        <td>User</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedReceipts.receipts.map((receipt, index) => (
                                        <tr>
                                            <td className='w-[10%] p-2'>{index + 1}</td>
                                            <td className='w-[15%]'>{receipt.receipt_id}</td>
                                            <td className='w-[50%]'>{receipt.title}</td>
                                            <td className='w-[30%]'>{receipt.user_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex justify-between mt-8">
                            <div className="p-2 px-6 rounded-full cursor-pointer border border-gray-200 hover:bg-gray-100"
                                onClick={handleCancel}>
                                Cancel
                            </div>
                            <div className="p-2 px-6 bg-[#EB0000] text-white rounded-full cursor-pointer hover:bg-red-600"
                                onClick={handleDelete}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") handleDelete();
                                }}>
                                Delete
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-6 px-8 w-[650px]">
                    <h1 className="text-xl text-[#667085] leading-8">A jeni te sigurt qe deshironi te fshini kete {details.category}?</h1>
                    <h3 className="text-sm text-gray-400 mt-4">A jeni te sigurt qe deshironi te fshini <span className="text-black font-semibold">{details.name}?</span></h3>
                    <div className="flex justify-between mt-8">
                        <div className="p-1 px-6 rounded-full cursor-pointer border border-gray-200 hover:bg-gray-100" onClick={handleCancel}>Cancel</div>
                        <div className="p-1 px-6 bg-[#EB0000] text-white rounded-full cursor-pointer hover:bg-red-600" onClick={handleDelete}>Delete</div>
                    </div>
                </div>
            </Modal>
        </div>)
}