import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const prizeApi = createApi({
  reducerPath: "prizeApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
  }),
  endpoints: (build) => ({
    getGameDetails: build.query({
      query() {
        return {
          url: "/admin/prizes/games",
          method: "GET",
        };
      },
      providesTags: ["Games"],
    }),
    getWinners: build.query({
      query() {
        return {
          url: "/admin/winners",
          method: "GET",
        };
      },
      invalidatesTags: ["Prizes"],
    }),
    generateWinners: build.mutation({
      query(data) {
        return {
          url: "/admin/prizes/generate-winners",
          method: "POST",
          data: data,
        };
      },
    }),
    getUsersWithFilledConditions: build.query({
      query(params) {
        return {
          url: "/users/usersWithFilledConditions",
          method: "GET",
          params: params
        };
      },
      invalidatesTags: ["Prizes"],
    }),
    closeGame: build.mutation({
      query({ game_id }) {
        return {
          url: `/admin/prizes/games/end/${game_id}`,
          method: "PATCH",
        };
      },
      invalidatesTags: ['Games']
    })
  }),
});

export const {
  useGetGameDetailsQuery,
  useGetWinnersQuery,
  useGenerateWinnersMutation,
  useGetUsersWithFilledConditionsQuery,
  useLazyGetUsersWithFilledConditionsQuery,
  useCloseGameMutation
} = prizeApi;
