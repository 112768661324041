import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { complainReplyActions } from '../store/complainReplySlice';
import { complaintViewActions } from '../store/complaintViewSlice';
import { TbMessageReport } from "react-icons/tb";
import { LuReply } from 'react-icons/lu';
import { IoMdArrowBack } from "react-icons/io";
import { MdDone } from 'react-icons/md';

const customStyles = {
    content: {
        position: 'static',
    },
    overlay: {
        background: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

Modal.setAppElement(document.getElementById('root'))

export default function ComplaintView() {

    const data = useSelector(state => state.complaint_view.data)
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.complaint_view.isOpen)

    const [title, setTitle] = useState('')
    const [body, setBody] = useState('')

    function closeModal() {
        dispatch(complaintViewActions.closeModal())
    }

    const handleCancel = () => {
        dispatch(complaintViewActions.closeModal())
    }

    const handleReplyClick = () => {
        dispatch(complainReplyActions.openModal(data))
        closeModal();
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="bg-white p-4 w-[600px]">
                    <div className=''>
                        <div className='mb-8 font-bold text-lg text-center'>Complaint details</div>
                        <div className='flex flex-col gap-4 w-full'>
                            <div className='flex items-center justify-between text-sm'>
                                <div><span className='font-bold'>From</span>: {data.name} ({data.email})</div>
                                <div><span className='font-bold'>Type</span>: {data.complaint_type}</div>
                            </div>
                            <div className='flex flex-col gap-1'>
                                <input
                                    type='text'
                                    placeholder='Title'
                                    className='border border-gray-300 p-3 w-full rounded-lg text-sm'
                                    value={data.title}
                                    disabled
                                />
                            </div>
                            <div className='flex flex-col gap-1'>
                                <textarea
                                    type='text'
                                    placeholder='Body'
                                    className='border border-gray-300 p-3 h-40 w-full rounded-lg text-sm resize-none'
                                    value={data.body}
                                    disabled
                                />
                            </div>
                            <div className="flex justify-between mt-2">
                                <div className="flex items-center gap-2 p-1 px-6 rounded-full cursor-pointer border border-gray-300 hover:bg-gray-100"
                                    onClick={handleCancel}>
                                    <span><IoMdArrowBack size={18} /></span>
                                    <span>Back</span>
                                </div>
                                {!data.isReplied ? (
                                    <div className="p-1 px-6 bg-primary text-white rounded-full cursor-pointer flex items-center gap-2 hover:bg-primary/80"
                                        onClick={handleReplyClick}
                                    >
                                        <span><LuReply size={18} /></span>
                                        <span>Reply</span>
                                    </div>
                                ) : (
                                    <div className="p-1 px-6 bg-gray-50 rounded-full flex items-center gap-2 text-gray-300"
                                    >
                                        <span className='leading-3'>Replied</span>
                                        <span className='rounded-full w-4 h-4 flex items-center justify-center bg-gray-300 text-white'><MdDone size={12} /></span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>)
}