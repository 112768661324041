import PrizesListItem from "./PrizesListItem";

export default function PrizesList({ prizes }) {
  return (
    <>
      {prizes?.map((prize) => (
        <PrizesListItem prize={prize} />
      ))}
    </>
  );
}
