import { createSlice } from "@reduxjs/toolkit";

const initialAuditLogViewState = { data: {}, isOpen: false }

const complainReplySlice = createSlice({
    name: 'complain_reply_slice',
    initialState: initialAuditLogViewState,
    reducers: {
        openModal(state, action) {
            state.data = action.payload
            state.isOpen = true
        },
        closeModal(state, action) {
            state.data = {}
            state.isOpen = false
        }
    }
})

export const complainReplyActions = complainReplySlice.actions;
export default complainReplySlice