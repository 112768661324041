import { toast, ToastContainer } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { useCreateGameMutation } from "../../store/services/gameServices";
import DashboardHeader from "../DashboardHeader";
import { DateRangePicker } from "react-date-range";
import { enUS } from "react-date-range/dist/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import { useLazyGetNACCodesQuery } from "../../store/services/nacServices";
import { cities } from "../notifications/AddNotification";

const validationSchema = Yup.object({
  title: Yup.string().required("Titulli është i detyrueshëm!"),
  description: Yup.string().required("Përshkrimi është i detyrueshëm!"),
  phases: Yup.array().of(
    Yup.object().shape({
      required_receipts: Yup.number()
        .min(1, "Faturat e kërkuara duhet të jenë më të mëdha se zero!")
        .required("Faturat e kërkuara janë të detyrueshme!"),
      winning_amount: Yup.number()
        .min(1, "Shuma fituese duhet të jetë më e madhe se zero!")
        .required("Shuma fituese është e detyrueshme!"),
    })
  ),
});

export default function GameRules() {
  const [createGame] = useCreateGameMutation();
  const navigate = useNavigate()
  const [searchTextInput, setSearchTextInput] = useState('')
  const [nacCode, setNacCode] = useState(null)
  const [nacOptions, setNacOptions] = useState([])
  const debounceTimeoutRef = useRef(null);
  const [regions, setRegions] = useState([])

  const [getNacCodes, { data: nacData }] = useLazyGetNACCodesQuery()

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const start_date = values.dateRange[0].startDate
        ? values.dateRange[0].startDate.toISOString().split("T")[0]
        : null;

      const end_date = values.dateRange[0].endDate
        ? values.dateRange[0].endDate.toISOString().split("T")[0]
        : null;

      if (!start_date || !end_date) {
        toast.error("Zgjidhni një periudhë të vlefshme për lojën!");
        setSubmitting(false);
        return;
      }

      const gameData = {
        title: values.title,
        description: values.description,
        zip_codes: regions.length > 1 ? regions.map(region => region.value).join(',') : null,
        NAC_codes: nacCode.length > 1 ? nacCode.map(code => code.value).join(',') : null,
        start_date,
        end_date,
        tiers: values.phases,
      };

      await createGame(gameData).unwrap();
      toast.success("Loja u krijua me sukses!");
      setTimeout(() => {
        navigate('/games')
      }, [1000])
    } catch (error) {
      toast.error("Krijimi i lojës dështoi!");
      console.error("Failed to create game:", error);
    }

    setSubmitting(false);
  };

  const buttons = [
    {
      label: '',
      color: 'bg-white invisible',
      border: 'border border-[#D0D5DD]',
      linkTo: '/receipts',
      onClick: null,
      shadow: true
    },
  ];

  const handleReceipientsSearchChange = (value) => {
    const text = value
    setSearchTextInput(text)

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (text.length > 2) {
      debounceTimeoutRef.current = setTimeout(async () => {
        // trigger the users function
        const result = await getNacCodes({ q: text })
        console.log({ result })
        if (result?.data) {
          if (result.data.message) {
            setNacOptions([])
          }
          else {
            setNacOptions(result.data)
          }
        }
      }, 500)
    }
    else {
      setNacOptions([])
    }
  }

  const handleNacCodeChange = (value) => {
    if (value.value === null) {
      setNacCode(null)
      return;
    }
    setNacCode(value)
  }

  const handleRegionChange = (value) => {
    setRegions(value)
  }

  useEffect(() => {
    console.log({ nacCode })
  }, [nacCode])

  return (
    <div>
      <DashboardHeader buttons={buttons} details={{ title: "Game Rules" }} />
      <hr />
      <div className="p-4">
        <div className="flex flex-col gap-8 p-4">
          <Formik
            initialValues={{
              title: "",
              description: "",
              zip_code: "",
              nac_code: "",
              dateRange: [{ startDate: null, endDate: null, key: "selection" }],
              phases: [{ required_receipts: "", winning_amount: "" }],
            }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <div className="flex flex-col gap-8">
                  <div className="flex flex-col gap-4 lg:flex-row lg:gap-10">
                    <div className="flex flex-col gap-6 w-full border rounded-md p-5">
                      <div>
                        <label htmlFor="title" className="text-sm font-medium">
                          Titulli
                        </label>
                        <Field
                          type="text"
                          name="title"
                          id="title"
                          className="w-full border shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                          placeholder="Shkruaj titullin"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="description"
                          className="text-sm font-medium"
                        >
                          Përshkrimi
                        </label>
                        <Field
                          type="text"
                          name="description"
                          id="description"
                          className="w-full border shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                          placeholder="Shkruaj përshkrimin"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="description"
                          className="text-sm font-medium"
                        >
                          Regjioni
                        </label>
                        <Select
                          isMulti
                          name="zip_codes"
                          options={cities.map(city => {
                            return {
                              value: city.zip_code,
                              label: city.name
                            }
                          })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Të gjitha regjionet"
                          onChange={handleRegionChange}
                          value={regions}
                        />
                      </div>
                      <div>
                        <label htmlFor="region" className="text-sm font-medium">ARBK aktivitetet</label>
                        <Select
                          isMulti
                          name="arbk"
                          options={[...nacOptions?.map(option => {
                            return {
                              value: option.code,
                              label: option.description
                            }
                          })]}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Të gjitha (shkruaj emrin ose kodin për të zgjedhur)"
                          onChange={handleNacCodeChange}
                          value={nacCode}
                          onInputChange={handleReceipientsSearchChange}
                        />
                      </div>
                      <div className="flex flex-col gap-1">
                        <label className="text-sm font-medium">Data</label>
                        <DateRangePicker
                          ranges={values.dateRange}
                          direction="horizontal"
                          locale={enUS}
                          onChange={(item) =>
                            setFieldValue("dateRange", [item.selection])
                          }
                          moveRangeOnFirstSelection={false}
                          showSelectionPreview={false}
                          staticRanges={[]}
                          inputRanges={[]}
                          className="mt-4"
                          rangeColors={["#bbb"]}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col w-full gap-4">
                      {values.phases.map((_, index) => (
                        <div className="flex flex-col gap-5">
                          <div className="flex justify-between items-center">
                            <label>Kushti {index + 1} i lojës</label>
                            <div className="flex gap-3">
                              {index > 0 && (
                                <div
                                  className="flex items-center justify-center border  shadow-sm rounded-full w-9 h-9 cursor-pointer"
                                  onClick={() => {
                                    const updatedPhases = values.phases.filter(
                                      (_, i) => i !== index
                                    );
                                    setFieldValue("phases", updatedPhases);
                                  }}
                                >
                                  <img src="/pics/delete.png" />
                                </div>
                              )}

                              <div
                                className="flex items-center justify-center border  shadow-sm rounded-full w-9 h-9 cursor-pointer"
                                onClick={() =>
                                  setFieldValue("phases", [
                                    ...values.phases,
                                    {
                                      required_receipts: "",
                                      winning_amount: "",
                                    },
                                  ])
                                }
                              >
                                <img src="/pics/plus.png" />
                              </div>
                            </div>
                          </div>
                          <div
                            key={index}
                            className="flex flex-col gap-6 border rounded-md p-5 relative"
                          >
                            <div className="flex flex-col gap-5">
                              <div>
                                <label
                                  htmlFor={`phases.${index}.required_receipts`}
                                  className="text-sm font-medium"
                                >
                                  Faturat e kërkuara
                                </label>
                                <Field
                                  type="number"
                                  name={`phases.${index}.required_receipts`}
                                  placeholder="Shkruaj faturat e kerkuara"
                                  className="w-full border shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                                  min="1"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`phases.${index}.required_receipts`}
                                  component="div"
                                  className="text-red-500 text-xs"
                                />
                              </div>

                              <div>
                                <label
                                  htmlFor={`phases.${index}.winning_amount`}
                                  className="text-sm font-medium"
                                >
                                  Shuma fituese
                                </label>
                                <Field
                                  type="number"
                                  name={`phases.${index}.winning_amount`}
                                  placeholder="Shkruaj shumen fituese"
                                  className="w-full border shadow-sm rounded-md py-[10px] px-4 placeholder-gray-500"
                                  min="1"
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name={`phases.${index}.winning_amount`}
                                  component="div"
                                  className="text-red-500 text-xs"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <div className="w-1/2 flex gap-4">
                      <button className="p-3 w-full bg-white border rounded-lg" onClick={(e) => {
                        e.preventDefault();
                        navigate('/games')
                      }}>
                        Anulo
                      </button>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="p-3 w-full bg-primary text-white rounded-lg"
                      >
                        {isSubmitting ? "Duke krijuar..." : "Krijo lojën"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={1000} />
    </div>
  );
}
