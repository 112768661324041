import { LuTrash2 } from 'react-icons/lu'
import { useDispatch } from 'react-redux'
import { modalActions } from '../../store/modalSlice'
import { tBodyItemStyle } from '../users/UserListItem'
import { FiEdit } from 'react-icons/fi'
import { editItemModalActions } from '../../store/editItemModalSlice'
import { complainReplyActions } from '../../store/complainReplySlice'
import { MdDone } from 'react-icons/md'
import { complaintViewActions } from '../../store/complaintViewSlice'
import { LuReply } from "react-icons/lu";

export default function ComplaintsListItem({ item }) {

    const dispatch = useDispatch()

    const handleEdit = (e) => {
        e.stopPropagation();
        dispatch(complainReplyActions.openModal(item))
    }

    const handleRowClick = () => {
        dispatch(complaintViewActions.openModal(item))
    }

    return (
        <>
            <tr className='table_row' onClick={handleRowClick}>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">
                    {item?.title.length > 30 ? item.title.slice(0, 30) + '...' : item.title}
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[30%]">
                    {item?.body.length > 50 ? item.body.slice(0, 50) + '...' : item.body}
                </td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[20%]">{item?.complaint_type}</td>
                <td style={tBodyItemStyle} className="text-black font-medium w-[15%]">{item?.name}</td>
                <td style={tBodyItemStyle} className='w-[15%]'>
                    {item?.isReplied ? (
                        <div className="flex justify-center gap-8 text-black">
                            <div className='flex items-center gap-2 text-sm px-5 p-1 text-gray-300'>
                                <span className='leading-3'>Replied</span>
                                <span className='rounded-full w-4 h-4 bg-black flex items-center justify-center bg-gray-300 text-white'><MdDone size={12} /></span>
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center items-center gap-4 text-black">
                            <div className='text-sm flex items-center gap-1 px-4 p-1 bg-white text-primary border border-primary hover:bg-primary hover:text-white rounded-full' onClick={handleEdit}>
                                <span><LuReply size={18} /></span>
                                <span>Reply</span>
                            </div>
                        </div>
                    )}
                </td>
            </tr>
        </>
    )
}