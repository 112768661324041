import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../../utils/axiosBaseQuery';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api`,
    }),
    endpoints: (build) => ({
        getUsers: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/admin/users/?${queryParams}`,
                    method: 'GET'
                }
            },
            providesTags: ['Users']
        }),
        getAllUsers: build.query({
            query(params) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/users/getAllActiveUsers?${queryParams}`,
                    method: 'GET'
                }
            },
            // providesTags: ['Users']
        }),
        getUserById: build.query({
            query(id) {
                return {
                    url: `/admin/users/${id}`,
                    method: 'GET'
                }
            },
            providesTags: ['User']
        }),
        deleteUser: build.mutation({
            query(id) {
                return {
                    url: `/users/${id}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['Users']
        }),
        createUser: build.mutation({
            query: (data) => ({
                url: '/admin/users',
                method: 'POST',
                data: data,
            }),
            invalidatesTags: ['Users']
        }),
        updateUser: build.mutation({
            query: ({ id, data }) => ({
                url: `/users/${id}`,
                method: 'PUT',
                data: data,
            }),
            invalidatesTags: ['Users', 'User']
        }),
    }),
});

export const { useGetUsersQuery, useLazyGetUsersQuery, useGetAllUsersQuery, useGetUserByIdQuery, useDeleteUserMutation, useCreateUserMutation, useUpdateUserMutation } = userApi;