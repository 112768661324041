import React, { useEffect, useState } from "react";
import { cities } from "../notifications/AddNotification";

const GameStatistics = ({ data }) => {

    const [totalWinnersByTierCount, setTotalWinnersByTierCount] = useState()
    const [totalReceiptsCount, setReceiptsCount] = useState()
    const [totalWinnersByRegionCount, setTotalWinnersByRegionCount] = useState()

    useEffect(() => {
        if (data) {
            setTotalWinnersByTierCount(data.winners_by_tier.reduce((sum, item) => sum + item.total_winners, 0))
            setReceiptsCount(data.winners_by_tier.reduce((sum, item) => sum + parseInt(item.total_receipts), 0))
            setTotalWinnersByRegionCount(data.winners_by_zip.reduce((sum, item) => sum + item.total_winners, 0))
        }
    }, [data])

    if (data?.winners_by_tier.length <= 0) {
        return (
            <div className="italic text-gray-600 py-2">Nuk ka të dhëna për këtë lojë</div>
        )
    }

    return (
        <div className="max-w-6xl mx-auto p-8 rounded-lg">
            <header className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-900">
                    📊 Raporti i Statistikave
                </h1>
                <p className="text-gray-600 mt-2">
                    Të dhënat e fundit për fituesit dhe faturat
                </p>
            </header>
            <div className="flex flex-col gap-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <WinnersTable title="📩 Numri i fituesve" data={data} total={totalWinnersByTierCount} />
                    <ReceiptsTable
                        title="✅ Fatura të verifikuara"
                        data={data}
                        total={totalReceiptsCount}
                    />
                </div>

                <GroupTable
                    title="🌍 Numri i fituesve sipas rajoneve"
                    data={data}
                    total={totalWinnersByRegionCount}
                />
            </div>

        </div>
    );
};

const ReceiptsTable = ({ title, data, total }) => {

    console.log({ data })

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>
            <div className="overflow-x-auto">
                <table className="w-full text-left border-collapse">
                    <thead>
                        <tr className="bg-primary text-white">
                            <th className="p-3">📌 Grupi</th>
                            <th className="p-3 text-right">📊 Numri</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.winners_by_tier.map((item, index) => (
                            <tr key={index} className="border-b hover:bg-blue-50 transition">
                                <td className="p-3 font-medium">Grupi {item.tier_level}</td>
                                <td className="p-3 text-right font-semibold">{item.total_receipts}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr className="bg-blue-100 font-bold">
                            <td className="p-3">🔢 Totali</td>
                            <td className="p-3 text-right">{total}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

const WinnersTable = ({ title, data, total }) => {

    console.log({ data })

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>
            <div className="overflow-x-auto">
                <table className="w-full text-left border-collapse">
                    <thead>
                        <tr className="bg-primary text-white">
                            <th className="p-3">📌 Grupi</th>
                            <th className="p-3 text-right">📊 Numri</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.winners_by_tier.map((item, index) => (
                            <tr key={index} className="border-b hover:bg-blue-50 transition">
                                <td className="p-3 font-medium">Grupi {item.tier_level}</td>
                                <td className="p-3 text-right font-semibold">{item.total_winners}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr className="bg-blue-100 font-bold">
                            <td className="p-3">🔢 Totali</td>
                            <td className="p-3 text-right">{total}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

const GroupTable = ({ title, data, total }) => {

    console.log({ data })

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>
            <div className="overflow-x-auto">
                <table className="w-full text-left border-collapse">
                    <thead>
                        <tr className="bg-primary text-white">
                            <th className="p-3">📌 Rajoni</th>
                            <th className="p-3 text-right">📊 Numri</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.winners_by_zip.map((item, index) => (
                            <tr key={index} className="border-b hover:bg-blue-50 transition">
                                <td className="p-3 font-medium">{cities?.find(city => city.zip_code === item.zip_code)?.name}</td>
                                <td className="p-3 text-right font-semibold">{item.total_winners}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr className="bg-blue-100 font-bold">
                            <td className="p-3">🔢 Totali</td>
                            <td className="p-3 text-right">{total}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default GameStatistics;