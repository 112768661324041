import { useEffect, useState } from "react"
import DashboardFilters from "../DashboardFilters"
import DashboardHeader from "../DashboardHeader"
import { PiArrowLeftBold, PiArrowRightBold } from "react-icons/pi"
import { useGetAccessoriesPerBrandQuery, useGetAccessoriesQuery } from "../../store/services/accessoryServices"
import CircularProgress from '@mui/material/CircularProgress';
import AccessoriesDashboardTable from "../AccessoriesDashboardTable"
import { useGetBrandsQuery } from "../../store/services/brandServices"
import AccessoriesFilters from "../AccessoriesFilters"
import { useNavigate } from "react-router"
import Pagination from "../Pagination"
import DashboardTable from "../DashboardTable"
import { useGetPropertyTypesQuery } from "../../store/services/propertyTypeServices"
import AccessoriesList from "./AnalyticsList"
import { BiPlus } from "react-icons/bi"
import { modalActions } from "../../store/modalSlice"
import { useDispatch, useSelector } from "react-redux"
import AddItemModal from "../AddItemModal"
import ReactModal from "../ReactModal"
import { createItemModalActions } from "../../store/createItemModalSlice"
import EditItemModal from "../EditItemModal"
import AnalyticsList from "./AnalyticsList"
import { useGetAuditLogsQuery } from "../../store/services/auditLogServices"
import AuditLogView from "./AuditLogView"

const analytics = [
    {
        id: 1,
        user: 'Shend',
        user_role: 'admin',
        category: 'Receipts',
        action: 'CREATE',
        created_at: '01/11/2024, 12:21'
    },
    {
        id: 2,
        user: 'Armir',
        user_role: 'user',
        category: 'Receipts',
        action: 'UPDATE',
        created_at: '01/11/2024, 12:21'
    },
    {
        id: 3,
        user: 'Shend',
        user_role: 'user',
        category: 'Receipts',
        action: 'SUBMIT',
        created_at: '01/11/2024, 12:21'
    },
    {
        id: 4,
        user: 'Shend',
        user_role: 'user',
        category: 'Receipts',
        action: 'SOFT DELETE',
        created_at: '01/11/2024, 12:21'
    },
    {
        id: 5,
        user: 'Shend',
        user_role: 'user',
        category: 'Receipts',
        action: 'CREATE',
        created_at: '01/11/2024, 12:21'
    },
    {
        id: 6,
        user: 'Shend',
        user_role: 'user',
        category: 'Receipts',
        action: 'ARCHIVED',
        created_at: '01/11/2024, 12:21'
    },
    {
        id: 7,
        user: 'Shend',
        user_role: 'user',
        category: 'Receipts',
        action: 'UPDATE',
        created_at: '01/11/2024, 12:21'
    },
    {
        id: 8,
        user: 'Shend',
        user_role: 'user',
        category: 'Receipts',
        action: 'SOFT DELETE',
        created_at: '01/11/2024, 12:21'
    }
]

export default function AnalyticsMain() {

    const [showFilters, setShowFilters] = useState(true)
    const [filterSelected, setFilterSelected] = useState("")
    const [pages, setPages] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [params, setParams] = useState({})
    const [isLoading, setIsLoading] = useState(false) // delete
    const [isFetching, setIsFetching] = useState(false) // delete

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))

    const { data } = useGetAuditLogsQuery({ params })
    const { isModalOpen, modalData } = useSelector(state => state.audit_log_view)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const newParams = {}
        query.forEach((value, key) => {
            newParams[key] = value
        })
        if (query.has("page")) {
            setCurrentPage(parseInt(query.get("page")))
        }
        // if (query.has("q")) {
        //     setSearchTextInput(query.get("q"))
        // }
    }, [query])

    useEffect(() => {
        const urlParams = new URLSearchParams(params).toString()
        navigate(`/audit_logs?${urlParams}`)
    }, [params, navigate])

    const details = {
        title: 'Audit Logs',
        count: data?.totalCount
    }

    const buttons = [
        {
            label: '',
            color: 'bg-white',
            border: 'border border-gray-300 invisible',
            linkTo: '/property_types'
        },
        // {
        //     label: 'Shto tip te prones',
        //     color: 'bg-gradient-to-t from-[#E2EB70] to-[#E8EF8A]',
        //     border: 'border border-b-2 border-primary',
        //     icon: <BiPlus />,
        //     shadow: 'shadow-md',
        //     onClick: () => dispatch(createItemModalActions.openModal({ details: { category: 'tip te prones' } })),
        // }
    ]

    // useEffect(() => {
    //     if (data) {
    //         setFilteredAccessories(data)
    //     }
    // }, [data])

    const handleSearch = (e) => {
        // if (!e.target.value) {
        //     setFilteredAccessories(data)
        //     return
        // }
        // setFilteredAccessories(data.filter(accessory => accessory.accessory_name.toLowerCase().startsWith(e.target.value.toLowerCase())))
    }

    const handleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page)
        setParams({ ...params, page: page })
    }

    const handleFilterSelect = (e) => {
    }

    const filters = {
        handleSearch: handleSearch,
        showFilters: showFilters,
        handleShowFilters: handleShowFilters,
        categories: [],
        selectedFilter: filterSelected,
        handleFilterSelect: handleFilterSelect
    }

    const tableData = {
        headLabels: ['#', 'ID', 'Created At', 'Action', 'Category', 'User', 'User Role'],
        data: data?.data,
        list: <AnalyticsList analytics={data?.data} />,
        actions: true
    }

    return (
        <>
            <ReactModal />
            <AuditLogView />
            <DashboardHeader details={details} buttons={buttons} />
            <hr />
            <DashboardFilters filters={filters} />
            <hr />
            {/* <AccessoriesFilters filters={filters} /> */}
            <div className="w-full relative">
                {isLoading ? (
                    <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-[800px] flex items-center justify-center text-primary">
                        <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                    </div>
                ) : (
                    isFetching && (
                        <div className="w-full z-30 absolute top-0 left-0 bg-transparent backdrop-blur-sm h-full flex items-center justify-center text-primary">
                            <CircularProgress thickness={6} size={40} color="var(--primary-color)" />
                        </div>
                    )
                )}
                <DashboardTable tableData={tableData} />
            </div>
            <Pagination currentPage={currentPage} totalPages={data?.totalPages} onPageChange={handlePageChange} />
        </>
    )
}