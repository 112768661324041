import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from "../../utils/axiosBaseQuery";

export const reportsApi = createApi({
    reducerPath: 'reportsApi',
    baseQuery: axiosBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/admin`,
    }),
    endpoints: (build) => ({
        getFilteredUsers: build.query({
            query(params) {
                return {
                    url: `/users/filter`,
                    method: 'GET',
                    params: params
                }
            },
        }),
        getFilteredReceipts: build.query({
            query({ params }) {
                console.log({ params })
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/receipts/filter?${queryParams}`,
                    method: 'GET'
                }
            }
        }),
        getFilteredWinners: build.query({
            query({ params }) {
                const queryParams = new URLSearchParams(params).toString()
                return {
                    url: `/winners/filter?${queryParams}`,
                    method: 'GET'
                }
            }
        }),
    }),
});

export const {
    useGetFilteredUsersQuery,
    useLazyGetFilteredUsersQuery,
    useGetFilteredReceiptsQuery,
    useLazyGetFilteredReceiptsQuery,
    useGetFilteredWinnersQuery,
    useLazyGetFilteredWinnersQuery
} = reportsApi;