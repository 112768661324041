import GameRulesListItem from "./GameRulesListItem";

export default function GameRulesList({ game }) {
  return (
    <>
      {game?.map((game) => (
        <GameRulesListItem game={game} />
      ))}
    </>
  );
}
