import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
import { notificationViewActions } from "../../store/services/notificationViewSlice";

const customStyles = {
  content: {
    position: "static",
    border: "none",
    borderRadius: "12px",
    padding: "0",
    maxWidth: "90vw",
    maxHeight: "90vh",
    overflow: "auto",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
};

Modal.setAppElement(document.getElementById("root"));

export default function NotificationView() {
  const data = useSelector((state) => state.notification_view.data);
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.notification_view.isOpen);

  function closeModal() {
    dispatch(notificationViewActions.closeModal());
  }

  const handleCancel = () => {
    dispatch(notificationViewActions.closeModal());
  };

  const renderImages = () => {
    const imagePaths = Array.isArray(data.image_path)
      ? data.image_path
      : [data.image_path];

    return (
      <div
        className={`grid ${
          imagePaths.length > 1
            ? "grid-cols-2 gap-4 max-h-[400px] overflow-y-auto"
            : "grid-cols-1"
        } justify-center items-center`}
      >
        {imagePaths.map(
          (image, index) =>
            image && (
              <div key={index} className="flex justify-center">
                <img
                  src={`${process.env.REACT_APP_BACKEND_HOST}/api/${image}`}
                  alt="Notification"
                  className="rounded-lg w-full max-h-[250px] object-cover shadow-md"
                />
              </div>
            )
        )}
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Notification Details"
    >
      <div className="bg-white p-6 md:p-8 w-full max-w-[600px] rounded-xl shadow-lg">
        <div className="mb-6 text-xl font-bold text-center text-gray-900">
          Notification Details
        </div>

        <div className="flex justify-center mb-6">{renderImages()}</div>

        <div className="flex items-center space-x-3 mb-4">
          <img src="/pics/atk.svg" alt="atk icon" width={36} />
          <span className="font-semibold text-gray-900">{data.title}</span>
          <div className="text-gray-700 text-xs border rounded-md border-gray-300 py-1 px-2">
            {data.created_at}
          </div>
        </div>

        <p className="text-gray-700 text-sm leading-relaxed">{data.body}</p>

        <div className="flex justify-between mt-6">
          <button
            className="flex items-center gap-2 p-2 px-6 rounded-full cursor-pointer border border-gray-300 hover:bg-gray-100 transition-all"
            onClick={handleCancel}
          >
            <IoMdArrowBack size={18} />
            <span>Back</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}
