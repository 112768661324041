import { createSlice } from "@reduxjs/toolkit";

const initialApprovedReceiptViewState = { receipt: {}, isOpen: false }

const approvedReceiptViewSlice = createSlice({
    name: 'approved_receipt_view',
    initialState: initialApprovedReceiptViewState,
    reducers: {
        openModal(state, action) {
            state.receipt = action.payload
            state.isOpen = true
        },
        closeModal(state, action) {
            state.receipt = {}
            state.isOpen = false
        }
    }
})

export const approvedReceiptViewActions = approvedReceiptViewSlice.actions;
export default approvedReceiptViewSlice