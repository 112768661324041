import React, { useEffect, useState } from "react";
import DashboardHeader from "../DashboardHeader";
import DashboardTable from "../DashboardTable";
import DashboardFilters from "../DashboardFilters";
import { BiPlus } from "react-icons/bi";
import Pagination from "../Pagination";
import GameRulesList from "./GameRulesList";
import { useGetGameRulesQuery, useGetGamesQuery } from "../../store/services/gameServices";
import { useNavigate } from "react-router";

const GameRulesTable = () => {
  const [showFilters, setShowFilters] = useState(true);
  const [filterSelected, setFilterSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState({});
  const { data } = useGetGamesQuery(params);
  const [query, setQuery] = useState(
    new URLSearchParams(window.location.search)
  );
  const [pages, setPages] = useState({});
  const [filteredArchivedReceipts, setFilteredArchivedReceipts] = useState([]);
  const navigate = useNavigate()

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    if (data) {
      console.log({ data });
      setFilteredArchivedReceipts(data?.data);
      setPages({
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        totalCount: data.totalCount,
      });
    }
  }, [data]);

  useEffect(() => {
    const newParams = {}
    query.forEach((value, key) => {
        newParams[key] = value
    })
    setParams(newParams);
    if (query.has("page")) {
        setCurrentPage(parseInt(query.get("page")))
    }
    // if (query.has("user_type")) {
    //     setFilterSelected(query.get("user_type"))
    // }
    // if (query.has("q")) {
    //     setSearchTextInput(query.get("q"))
    // }
}, [query])

  useEffect(() => {
    console.log({ params })
    const urlParams = new URLSearchParams(params).toString()
    navigate(`/games?${urlParams}`)
  }, [params, navigate])

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPages({ ...pages, currentPage: page });
    setParams({ ...params, page: page });
  };
  
  const details = {
    title: "Games",
  };

  const tableData = {
    headLabels: [
      "Title",
      "Description",
      "Tiers",
      "Start Date",
      "End Date",
      "Region",
      "NAC Codes",
    ],
    list: <GameRulesList game={data?.data} />,
    data: data,
    actions: false,
  };

  const addButton = [
    {
      label: "Add Game",
      color: "bg-primary text-white",
      border: "border border-b-2 border-primary",
      icon: <BiPlus />,
      shadow: "shadow-md",
      onClick: null,
      linkTo: "/games/add",
    },
  ];

  const filters = {
    categories: [],
    selectedFilter: filterSelected,
    handleFilterSelect: null,
    showFilters: showFilters,
    handleShowFilters: handleShowFilters,
  };

  return (
    <div>
      <DashboardHeader buttons={addButton} details={details} />
      <hr />
      <DashboardFilters filters={filters} />
      <div className="w-full">
        <DashboardTable tableData={tableData} />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={pages?.totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default GameRulesTable;
