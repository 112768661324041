import React, { useState, useEffect } from "react";
import "./DiceSpinner.css";

const DiceSpinner = () => {
  const [rolling, setRolling] = useState(true);
  const [rotation, setRotation] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (rolling) {
      const interval = setInterval(() => {
        // Random continuous rotation while rolling
        setRotation({
          x: Math.random() * 1440, // Up to 4 full rotations
          y: Math.random() * 1440,
        });
      }, 200); // Update every 200ms

      setTimeout(() => {
        setRolling(false);
        clearInterval(interval);

        // Set a final, specific face rotation
        setRotation({
          x: Math.floor(Math.random() * 4) * 90, // Lands on an exact side
          y: Math.floor(Math.random() * 4) * 90,
        });
      }, 3000); // Stop rolling after 3s

      return () => clearInterval(interval);
    }
  }, [rolling]);

  return (
    <div className="dice-container">
      <div
        className={`dice ${rolling ? "rolling" : ""}`}
        style={{
          transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
        }}
      >
        <div className="face front relative">
          <div className="absolute w-2 h-2 rounded-full bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
        </div>
        <div className="face back relative">
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] left-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-[50%] left-[15%] -translate-y-1/2"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-[50%] right-[15%] -translate-y-1/2"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] left-[15%]"></div>
        </div>
        <div className="face left relative">
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] left-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] left-[15%]"></div>
        </div>
        <div className="face right">
        <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] left-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] left-[15%]"></div>
        </div>
        <div className="face top">
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] left-[15%]"></div>
        </div>
        <div className="face bottom">
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] left-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] right-[15%]"></div>
          <div className="absolute w-2 h-2 rounded-full bg-white bottom-[15%] left-[15%]"></div>
        </div>
      </div>
    </div>
  );
};

export default DiceSpinner;
