import { FiUsers } from "react-icons/fi";
import DashboardHeader from "../DashboardHeader";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { useEffect, useRef, useState } from "react";
import DashboardTable from "../DashboardTable";
import UserList from "../users/UserList";
import { RiDownloadLine } from "react-icons/ri";
import ReceiptsList from "../receipts/ReceiptsList";
import Pagination from "../Pagination";
import FilterPopUp from "./FilterPopUp";
import { MdOutlineFilterList } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa";
import { useLazyGetFilteredReceiptsQuery, useLazyGetFilteredUsersQuery, useLazyGetFilteredWinnersQuery } from "../../store/services/reportsServices";
import { exportDataToExcel, exportToPdf } from "./utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PrizesList from "../prizes/PrizesList";
import { RiTableLine } from "react-icons/ri";
import Select from 'react-select';
import { useLazyGetGamesQuery, useLazyGetGameStatisticsQuery } from "../../store/services/gameServices";
import GameStatistics from "./GameStatistics";
import { MdOpenInFull } from "react-icons/md";
import { MdCloseFullscreen } from "react-icons/md";

export default function ReportsMain() {

    const isFirstRender = useRef(true);

    const [selectedActionId, setSeletedActionId] = useState(0)
    const [params, setParams] = useState({})
    const [category, setCategory] = useState('users')
    const [data, setData] = useState({
        data: [],
        currentPage: 1,
        totalPages: 1,
        totalCount: 1
    })

    const [query, setQuery] = useState(new URLSearchParams(window.location.search))
    const navigate = useNavigate()

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [downloadOptionsExtended, setDownloadOptionsExtended] = useState(false)
    const [filters, setFilters] = useState({})

    const [getReceiptsData, { data: receiptsData, isLoading: isReceiptsLoading, isFetching: isReceiptsFetching, isReceiptsError, isReceiptsSuccess }] = useLazyGetFilteredReceiptsQuery();
    const [getUsersData, { data: usersData, isLoading: isUsersLoading, isFetching: isUsersFetching, isUsersError, isUsersSuccess }] = useLazyGetFilteredUsersQuery();
    const [getWinnersData, { data: winnersData, isLoading: isWinnersLoading, isFetching: isWinnersFetching, isWinnersError, isWinnersSuccess }] = useLazyGetFilteredWinnersQuery()
    const [selectedGame, setSelectedGame] = useState(null)

    const downloadOptionsRef = useRef()

    const [getGames, { data: gamesData }] = useLazyGetGamesQuery()
    const [getGameStatistics, { data: gameStatistics }] = useLazyGetGameStatisticsQuery()

    const [isStatisticsFullScreen, setIsStatisticsFullScreen] = useState(false)

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                if (selectedActionId === 1) {
                    const data = await getGames().unwrap();
                    if (isMounted) {
                        console.log({ data });
                    }
                }
            } catch (error) {
                console.error("Error fetching games:", error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [selectedActionId, getGames]);

    useEffect(() => {
        const newParams = {};
        query.forEach((value, key) => {
            if (newParams[key]) {
                if (Array.isArray(newParams[key])) {
                    newParams[key] = [...value];
                } else {
                    newParams[key] = [newParams[key], value];
                }
            } else {
                newParams[key] = value;
            }
        });
        setParams(newParams);
        console.log({ newParams })
        const { page, category, ...filteredParams } = newParams;
        setFilters(filteredParams);
    }, [query]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (downloadOptionsRef.current && !downloadOptionsRef.current.contains(event.target)) {
                setDownloadOptionsExtended(false)
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setDownloadOptionsExtended]);

    const handleFilters = (filters) => {
        setFilters(filters)
    }

    const generateCurrentMonth = () => {
        const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const yyyy = firstDayOfMonth.getFullYear();
        const mm = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0'); // Ensures two digits
        const dd = String(firstDayOfMonth.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}`;

        return formattedDate;
    }

    const details = {
        title: 'Reports',
        // count: data?.totalCount
    }

    const headerButtons = [
        {
            label: 'Gjenero raportin',
            color: 'bg-white',
            icon: <RiDownloadLine />,
            border: 'border border-gray-300 invisible',
            linkTo: '/reports'
        }
    ]

    const buttons = [
        {
            label: 'Filterat',
            color: 'bg-white',
            icon: <MdOutlineFilterList />,
            border: 'border border-gray-300',
            onClick: () => {
                setIsPopupOpen(true)
            }
        },
        {
            label: 'Gjenero raportin',
            color: 'bg-white',
            icon: <RiDownloadLine />,
            border: 'border border-gray-300',
            onClick: () => {
                setDownloadOptionsExtended(!downloadOptionsExtended)
            }
        }

    ]

    const quickActions = [
        {
            id: 2,
            title: 'Users',
            label: 'Të gjithë',
            icon: <FiUsers size={22} />,
            clickHandler: async () => {
                let updatedParams = { ...params, page: 1, category: 'users' }
                delete updatedParams['start_date']
                setParams(updatedParams)
                setCategory('users')
                setData(data)
            }
        },
        {
            id: 3,
            title: 'Users',
            label: 'Të muajit aktual',
            icon: <FiUsers size={22} />,
            clickHandler: async () => {
                const currentMonth = generateCurrentMonth()
                setParams({ ...params, page: 1, start_date: currentMonth, category: 'users' })
            }
        },
        {
            id: 4,
            title: 'Kuponat',
            label: 'Të gjitha',
            icon: <HiOutlineDocumentText size={22} />,
            clickHandler: async () => {
                let updatedParams = { ...params, page: 1, category: 'receipts' }
                delete updatedParams['start_date']
                setParams(updatedParams)
            }
        },
        {
            id: 5,
            title: 'Kuponat',
            label: 'Të muajit aktual',
            icon: <HiOutlineDocumentText size={22} />,
            clickHandler: async () => {
                const currentMonth = generateCurrentMonth()
                setParams({ ...params, page: 1, start_date: currentMonth, category: 'receipts' })
            }
        },
        {
            id: 6,
            title: 'Fituesit',
            label: 'Të gjithë',
            icon: <HiOutlineDocumentText size={22} />,
            clickHandler: async () => {
                const currentMonth = generateCurrentMonth()
                setParams({ category: 'winners', page: 1 })
                setCategory('winners')
            }
        },
        {
            id: 7,
            title: 'Fituesit',
            label: 'Të lojës së fundit',
            icon: <HiOutlineDocumentText size={22} />,
            clickHandler: async () => {
                const currentMonth = generateCurrentMonth()
                setParams({ category: 'winners', page: 1, previous_month: true })
                setCategory('winners')
            }
        },
    ]

    const handlePageChange = async (page) => {
        setParams({ ...params, page: page })
        if (category === 'receipts') {
            const { data, error } = await getReceiptsData({ params: { ...params, page: page } })
            setData(data)
        }
        else {
            const { data, error } = await getUsersData({ ...params, page: page })
            setData(data)
        }
    }

    const tableData = {
        headLabels: category === 'users' ?
            ['Name & Surname', 'Created Date', 'Phone number', 'Email', 'Role'] :
            category === 'receipts' ?
                ['ID', 'Submission Date', 'NUI', 'User', 'Business Name', 'VAT Amount', 'Total Amount with VAT', 'Status'] :
                ["ID", "Name & Surname", "Email", "Receipt Count", "Tier Level", "Date"],
        list: category === 'users' ? <UserList users={data?.data} actions={false} /> : category === 'receipts' ? <ReceiptsList receipts={data?.data} actions={false} /> : <PrizesList prizes={data?.data} />,
        data: data,
        actions: false
    }

    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // Skip the first run
        }

        const queryString = Object.entries(params)
            .map(([key, value]) =>
                Array.isArray(value)
                    ? value.map(val => `${key}[]=${encodeURIComponent(val)}`).join('&')
                    : `${key}=${encodeURIComponent(value)}`
            )
            .join('&');

        navigate(`/reports?${queryString}`);
        const fetchData = async () => {
            try {
                if (!params['category']) {
                    const data = await getUsersData({}).unwrap();
                    setData(data);
                    setSeletedActionId(2);
                }
                else if (params['category'] === 'users') {
                    setCategory('users')
                    const data = await getUsersData(params).unwrap();
                    setData(data);
                    setSeletedActionId(params['start_date'] ? 3 : 2);
                }
                else if (params['category'] === 'receipts') {
                    setCategory('receipts')
                    const data = await getReceiptsData({ params }).unwrap();
                    setData(data);
                    setSeletedActionId(params['start_date'] ? 5 : 4);
                }
                else if (params['category'] == 'winners') {
                    setCategory('winners')
                    const data = await getWinnersData({ params }).unwrap();
                    setData(data)
                    setSeletedActionId(params['previous_month'] ? 7 : 6);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [params, navigate])

    const handleFiltersSubmit = async (category, filteredData) => {
        setParams({ ...filteredData, page: 1, category: category })
        setIsPopupOpen(false)
        setFilters(filteredData)
        setSeletedActionId(0)
    }

    const handleStatisticsReportClick = () => {
        setSeletedActionId(1)
    }

    const handleGameSelect = async (value) => {
        setSelectedGame(value)
        await getGameStatistics({ game_id: value.value }).unwrap()
    }

    useEffect(() => {
        console.log({ gameStatistics })
    }, [gameStatistics])

    return (
        <>
            {/* {isUsersLoading || isReceiptsLoading || isUsersFetching || isReceiptsFetching ? <Loading /> : null} */}
            <div className="h-full flex flex-col">
                <FilterPopUp
                    open={isPopupOpen}
                    setIsOpen={setIsPopupOpen}
                    onClose={() => setIsPopupOpen(false)}
                    handleFormSubmit={handleFiltersSubmit}
                    category={category}
                    handleFilters={handleFilters}
                    params={params}
                />
                <DashboardHeader details={details} buttons={headerButtons} />
                <hr />
                <div className="grid 2xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-4 p-4">
                    <div className={`w-full row-span-2 flex items-center p-4 rounded-xl border ${selectedActionId === 1 ? 'bg-primary text-white' : 'bg-white text-black hover:border-primary/50'} cursor-pointer`} onClick={handleStatisticsReportClick}>
                        <div className="flex w-full items-center justify-center">
                            <div className="flex flex-col items-center gap-4">
                                <div className="text-2xl font-semibold">Raport statistikor</div>
                                <div className={`w-20 h-20 flex items-center justify-center rounded-full border ${selectedActionId === 1 ? 'bg-primary border-gray-200 text-white' : 'text-primary border-primary'} text-4xl`}>
                                    📊
                                </div>
                            </div>
                        </div>
                    </div>
                    {quickActions.map((action, index) => (
                        <div key={index} className={`w-full p-4 rounded-xl border ${selectedActionId === action.id ? 'bg-primary text-white' : 'bg-white text-black hover:border-primary/50'} cursor-pointer`} onClick={action.clickHandler}>
                            <div className="flex items-center justify-between">
                                <div className="flex flex-col gap-4">
                                    <div>{action.title}</div>
                                    <div className="text-lg font-bold">{action.label}</div>
                                </div>
                                <div className={`rounded-full p-2 border ${selectedActionId === action.id ? 'bg-primary border-gray-200 text-white' : 'text-primary border-primary'}`}>
                                    {action.icon}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <hr />
                {selectedActionId === 1 ? (
                    <>
                        <div className={`w-full flex flex-col gap-4 p-4 ${isStatisticsFullScreen && 'absolute h-full top-0 left-0 bg-white'}`}>
                            <div className="w-full flex justify-between">
                                <div className="lg:w-1/2 w-full">
                                    <Select
                                        name="game_id"
                                        options={gamesData?.data?.map(game => {
                                            return {
                                                value: game.game_id,
                                                label: game.title
                                            }
                                        })}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Zgjedh lojen"
                                        onChange={handleGameSelect}
                                        value={selectedGame}
                                    />
                                </div>
                                <div className="lg:w-1/2 w-full flex justify-end p-2">
                                    {!isStatisticsFullScreen && gameStatistics?.winners_by_tier.length > 0 ? <MdOpenInFull cursor={"pointer"} size={24} onClick={() => setIsStatisticsFullScreen(true)} /> : null}
                                    {isStatisticsFullScreen && <MdCloseFullscreen cursor={"pointer"} size={24} onClick={() => setIsStatisticsFullScreen(false)} />}
                                </div>
                            </div>
                            <div>
                                {gameStatistics && <GameStatistics data={gameStatistics} />}
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="w-full">
                            <div className="p-4 flex justify-between">
                                {buttons.map((button, index) => (
                                    <div key={index} className="relative">
                                        <div ref={downloadOptionsRef} className="p-2 flex items-center gap-2 text-sm font-bold px-4 w-max border hover:border-gray-300 rounded-xl cursor-pointer" onClick={button.onClick}>
                                            <div>{button.label}</div>
                                            {button.icon}
                                        </div>
                                        {button.label === 'Gjenero raportin' && (
                                            <div ref={downloadOptionsRef} className={`absolute ${downloadOptionsExtended ? 'h-20' : 'h-0 overflow-hidden opacity-0'} w-full text-sm rounded-xl border flex flex-col bg-white z-10 transition-all`}>
                                                <div className="h-1/2 flex items-center justify-center gap-2 px-2 rounded-xl hover:bg-gray-100 cursor-pointer" onClick={() => exportDataToExcel(data?.data, "Raporti_GIZ_Dashboard", filters)}>
                                                    <RiFileExcel2Line size={16} color="green" />
                                                    <div>Excel file (.xlsx)</div>
                                                </div>
                                                <div className="h-1/2 flex items-center justify-center gap-2 px-2 rounded-xl hover:bg-gray-100 cursor-pointer" onClick={() => exportToPdf(data?.data)}>
                                                    <FaRegFilePdf size={16} color="red" />
                                                    <div>PDF file (.pdf)</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-col justify-between h-full">
                            {isUsersFetching || isReceiptsFetching ? (
                                <div className="w-full h-full flex items-center justify-center"><CircularProgress /></div>
                            ) : (
                                <>
                                    <div className="w-full relative">
                                        <DashboardTable tableData={tableData} />
                                    </div>
                                    <Pagination currentPage={data?.currentPage} totalPages={data?.totalPages} onPageChange={handlePageChange} />
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}